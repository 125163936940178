import React from 'react'
import Slider from './Slider'
import { FaUserCircle } from "react-icons/fa";

const CobruNavBar = ({ cobru = null, loading=false, error='' }) => {

    const returnString = () => {
        const urlCompleta = cobru?.profile_picture;
        const cadenaAContener = "https://s3.amazonaws.com/cobru-profile-pictures/";

        if (urlCompleta.includes(cadenaAContener) || urlCompleta.startsWith("uploads/")) {
            console.log(urlCompleta);

            return cadenaAContener + urlCompleta
        } else {
            return urlCompleta
        }
    }

    const getFirstLetter = () => {
        let final = '';
    
        if (cobru?.alias) {
            // Si tiene alias, tomar las dos primeras letras del alias
            final = cobru.alias.toString().substring(0, 2).toUpperCase();
        } else if (cobru?.first_name) {
            // Si no tiene alias pero tiene nombre, procesar las iniciales
            const first = cobru.first_name.toString().charAt(0).toUpperCase();
            const second = cobru?.last_name 
                ? cobru.last_name.toString().charAt(0).toUpperCase() 
                : cobru.first_name.toString().charAt(1).toUpperCase(); // Tomar la segunda letra del nombre si no hay apellido
            final = first + second;
        }
    
        return final;
    };
    

    if (!cobru) {
        return <></>
    }

    return (
        cobru?.images ?
            <>
                <div className="bg-header !h-60 w-full flex justify-center items-center flex-col">
                    <Slider images={cobru?.images/* [
                    'https://via.placeholder.com/600x400',
                    'https://via.placeholder.com/600x400',
                    'https://via.placeholder.com/600x400',
                    'https://via.placeholder.com/600x400',
                    'https://via.placeholder.com/600x400',
                    'https://via.placeholder.com/600x400',
                ] */} />
                    <div className="image-profile-container flex justify-start items-center relative">
                        {
                            cobru && cobru?.profile_picture?
                                <img src={returnString()} className="rounded-full w-20 absolute -bottom-8 "></img>
                                :
                                <>
                                    <div className='profile-img-bg rounded-full w-20 h-20 flex items-center justify-center absolute '>
                                        <p className='uppercase text-2xl'>
                                            {getFirstLetter()}
                                        </p>
                                    </div>
                                </>
                        }
                    </div>
                </div>
                {
                    cobru?.id &&
                    <div className="flex justify-end items-end image-profile-container mt-1">
                        <p className="text-left text-ref text-zinc-400">REF-{cobru?.id}</p>
                    </div>
                }
            </>
            :
            <div className="bg-header h-24 w-full flex justify-center relative ">
            <div className="bg-transparent absolute flex imagenContainer justify-center items-center !p-0">
                {cobru?.profile_picture ? (
                    <img 
                        src={returnString()} 
                        className="rounded-full w-20 absolute" 
                        style={{ marginTop: '10%' }}
                    />
                ) : (
                    <div 
                        className="profile-img-bg rounded-full w-20 h-20 flex items-center justify-center absolute"
                        style={{ marginTop: '10%' }}
                    >
                        <p className="uppercase text-2xl">{getFirstLetter()}</p>
                    </div>
                )}
                {cobru?.id && (
                    <div className="flex justify-end items-end w-full">
                        <p className="text-left text-ref">{`REF-${cobru?.id || ''}`}</p>
                    </div>
                )}
            </div>
        </div>
        
    )
}

export default CobruNavBar
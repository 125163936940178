// 1. Crear el contexto
import React, { createContext, useContext, useState } from 'react';
import { IMGS } from '../constants';

export const MainContext = createContext();
// 2. Crear el proveedor del contexto
export const MainProvider = ({ children }) => {
    const hostname = window?.location?.hostname;

    const [isCaptchaValid, setIsCaptchaValid] = useState(!false);
    const [showRecaptcha, setShowRecaptcha] = useState( false)
    const [globalCurrency, setGlobalCurrency] = useState('COP')
    const [isEzy, setIsEzy] = useState(hostname === 'link.ezytransfer.co' || false)
    const [isPagaHoy, setIsPagaHoy] = useState(hostname === 'link.pagahoy.com' || false)
    const [isTucanPay, setIsTucanpay] = useState(hostname === 'colombia.tucanpay.com' || false)
    //valores
    
    const getActiveVariable = () => {
        //const hostname = window?.location?.hostname;
        
        if (hostname === 'link.ezytransfer.co') {
            return 'ezytransfer';
        } else if (hostname === 'link.pagahoy.com') {
            return 'pagaHoy';
        } else if (hostname === 'link.cobru.co') {
            return 'cobru';
        } else if (hostname === 'colombia.tucanpay.com') {
            return 'tucanpay';
        } else {
            return ''; // No se encontró un servicio conocido
        }
    };

    /* const getActiveVariableBrand = () => {
        //const hostname = window?.location?.hostname;
        
        if (hostname === 'link.ezytransfer.co') {
            return 'ezytransfer' || 'ezy_transfer';
        } else if (hostname === 'link.pagahoy.com') {
            return 'pagaHoy';
        } /* else if (hostname === 'link.cobru.co') {
            return 'cobru';
        }  else if (hostname === 'colombia.tucanpay.com') {
            return 'tucanpay';
        } else {
            return 'NONE'; // No se encontró un servicio conocido
        }
    }; */
    const getActiveVariableBrand = (useUnderscore = false) => {
    const hostname = window?.location?.hostname;

    if (hostname === 'link.ezytransfer.co') {
        return useUnderscore ? 'ezy_transfer' : 'ezytransfer';
    } else if (hostname === 'link.pagahoy.com') {
        return 'pagaHoy';
    } /* else if (hostname === 'link.cobru.co') {
        return 'cobru';
    } */ else if (hostname === 'colombia.tucanpay.com') {
        return 'tucanpay';
    } else {
        return 'NONE'; // No se encontró un servicio conocido
    }
};
    

    const getServiceStyles = () => {
        const activeVariable = getActiveVariable()
        switch (activeVariable) {
            case 'ezytransfer':
                return {
                    color: '#FF5733', // Color ejemplo
                    styles: {
                        backgroundColor: '#FAD7A0',
                        fontSize: '16px',
                        padding: '10px'
                    },
                    name:'Ezytransfer',
                    image: 'ezy-logo.png',
                    logo: IMGS?.LOGOS?.ezyLogo // Cambia a la ruta de la imagen correspondiente
                };
            case 'pagaHoy':
                return {
                    color: '#28A745', // Color ejemplo
                    styles: {
                        backgroundColor: '#D4EDDA',
                        fontSize: '16px',
                        padding: '10px'
                    },
                    name:'Paga Hoy',
                    image: 'pagahoy-logo.png',
                    logo: IMGS?.LOGOS?.pagaHoyLogo // Cambia a la ruta de la imagen correspondiente
                };
            case 'cobru':
                return {
                    color: '#007BFF', // Color ejemplo
                    styles: {
                        backgroundColor: '#CCE5FF',
                        fontSize: '16px',
                        padding: '10px'
                    },
                    name:'Cobru',
                    image: 'cobru-logo.png',
                    logo: IMGS?.LOGOS?.cobruLogo // Cambia a la ruta de la imagen correspondiente
                };
            case 'tucanpay':
                return {
                    color: '#007BFF', // Color ejemplo
                    styles: {
                        backgroundColor: '#CCE5FF',
                        fontSize: '16px',
                        padding: '10px'
                    },
                    name:'Cobru',
                    image: 'cobru-logo.png',
                    logo: IMGS?.LOGOS?.tucanpayLogo // Cambia a la ruta de la imagen correspondiente
                };
            default:
                console.log('default', activeVariable)

                return {
                    color: '#000000', // Color por defecto
                    styles: {
                        backgroundColor: '#FFFFFF',
                        fontSize: '16px',
                        padding: '10px'
                    },
                    image: 'default-logo.png',// Imagen por defecto
                    logo: IMGS?.LOGOS?.cobruLogo // Cambia a la ruta de la imagen correspondiente

                };
        }
    };
    
    const value= {
        isCaptchaValid, setIsCaptchaValid, showRecaptcha, setShowRecaptcha,
        globalCurrency, setGlobalCurrency, isEzy, isPagaHoy, isTucanPay,
       getServiceStyles, getActiveVariableBrand
    }
    
    return (
        <MainContext.Provider value={value}>
            {children}
        </MainContext.Provider>
    )

}

import React, { useState, useEffect, Fragment, useContext } from "react";
import axios from "axios";
import { BsTelephoneFill } from "react-icons/bs";
import { MdMail } from "react-icons/md"
import { RiFileCopy2Fill } from "react-icons/ri"
import { logDOM } from "@testing-library/react";
import { FormatCreditCard, FormatExpirationDate, capitalizeSentence, copyClipbord, formatCOPNumber, formatCOPNumberInput, formatDocument, formatPhoneNumber, getDigits, getDigitsRegister, getFranchise, getNavInfo, isAPhone, millisecondsToMinutesAndSeconds, swapArrayElements, validateName } from "../functions";
import _, { values } from 'lodash'
import QRCode from "react-qr-code";
import Loading from '../components/Loading';
import { Carousel } from "../components/Carousel";
import PinPayInfo from "../components/PinPayInfo";
import ListItem from "../components/ListItem";
import Modal from "../components/Modal";
import checkIcon from '../assets/icons/check-icon.png'
import SelectModal from "../components/SelectModal";
import moment from "moment";
import { countries_new } from "../constants";
import NavBarCobru from "../components/NavBarCobru";
import Input from "../components/Input";
import SplitAmount from "../components/SplitAmount";
import useRecaptcha from "../hooks/useRecaptcha";
import ReCAPTCHA from "react-google-recaptcha";
import { MainContext } from "../contexts/MainContext";
import { useNavigate, useLocation } from 'react-router-dom';
import Slider from "../components/Slider";
import CobruNavBar from "../components/CobruNavBar";
import { useLanguage } from "../contexts/LanguageContext";
import Translate from "../components/Translate";
import EmailContact from "../components/EmailContact";
import PhoneContact from "../components/PhoneContact";
import UserNameComponent from "../components/UserNameComponent";
import { useGeo } from "../contexts/GeoContext";





function Home(props) {
    //const isEzy = window?.location?.hostname === 'link.ezytransfer.co' ? true : false
    //const isPagaHoy = window?.location?.hostname === 'link.pagahoy.com' ? true : false

    const location = useLocation();
    const navigate = useNavigate();
    const { setLanguage, translate } = useLanguage();
    const { countryFull } = useGeo()
    const { methods, selectedMethod, cobruURL, cobru, params, isAPhoneNumber } = location?.state
    console.log(methods);
    const [activated, setActivate] = useState(false)
    const [paymentsMethod, setPaymentsMethod] = useState(methods || null)
    const [payMethodName, setPayMethodName] = useState(null)
    const [loading, setLoading] = useState(false)
    const [paymentsMethodArray, setPaymentsMethodArray] = useState([])
    const [currentPaymentMethod, setCurrentPaymentMethod] = useState(null)
    const [showCobru, setShowCobru] = useState(true)
    const [formValues, setFormValues] = useState({
        name: '',
        docType: '',
        doc: '',
        email: '',
        phone: '',
        //amount: ''
    })
    const [errorMessages, setErrorMessages] = useState({
        amount: '',
        name: '',
        docType: '',
        doc: '',
        email: '',
        phone: '',
        //amount: ''
    })
    /*     const [formValues, setFormValues] = useState({
            name: 'Angel Rodriguez',
            docType: '1',
            doc: '1001855422',
            email: 'angel@prueba.com',
            phone: '3160497786'
        }) */
    const [creditCardNumber, setCreditCardNumber] = useState('')
    const [OTPNumber, setOTPNumber] = useState('')
    const [creditCardDate, setCreditCardDate] = useState('')
    const [creditCardCVV, setCreditCardCVV] = useState('')
    const [creditCardCuotas, setCreditCardCuotas] = useState({ id: 1, label: '1 Cuota', value: '1' })
    const [expDate, setExpDate] = useState('')
    const [showForm, setShowForm] = useState(true)
    const [showQr, setShowQr] = useState(false)
    const [successCheck, setSuccessCheck] = useState(false)
    const [showPin, setShowPin] = useState(false)
    const [currencyWallet, setCurrencyWallet] = useState('')

    const [qrValue, setQrValue] = useState(null)
    const [waitingNequiPay, setWaitingNequiPay] = useState(false)
    const [paymentDetails, setPaymentDetails] = useState({})
    const [paymentDetailsCobru, setPaymentDetailsCobru] = useState({})

    const [payPin, setPayPin] = useState({ code: '', ref: '' })
    const [countDown, setCountDown] = useState('00:00')
    const [countDowDaviplata, setCountDownDaviplata] = useState('00:00')
    const [runTimerCheck, setRunTimerCheck] = useState(null)
    const [runTimerCheckDaviplata, setRunTimerCheckDaviplata] = useState(null)
    const [currencyAmount, setCurrencyAmount] = useState('Calculando...')
    const [cryptoInterval, setCryptoInterval] = useState(null)
    const [daviplataInterval, setDaviplataInterval] = useState(null)
    const [bankList, setBankList] = useState([])
    const [amount, setAmount] = useState('')
    const [bankPse, setBankPse] = useState({ value: '', label: translate('select_bank_label') })
    const [showModal, setShowModal] = useState(false)

    const [errorMessage, setErrorMessage] = useState('')
    const [buttonName, setButtonName] = useState('')
    const [country, setCountry] = useState(
        { id: 0, label: 'Seleccionar', value: '', label2: '' },
    )
    const [item_, setItem_] = useState(null)
    const [showOTP, setShowOTP] = useState(false)
    //const [showOTPDaviplata, setShowOTPDaviplata] = useState(false)
    const [cobruItem, setCobruItem] = useState(false)
    const [isPhoneNumber, setIsPhoneNumber] = useState(false)
    const [cobruURL_, setCobruURL_] = useState('')
    const [daviplataInfo, setDaviplataInfo] = useState(null)
    const [trm, setTrm] = useState(null)
    const [isInt, setIsInt] = useState(false)
    const [description, setDescription] = useState('')
    const [amountOptions, setAmountOptions] = useState([
        { label: 'COP', value: 'COP' },
        { label: 'USD', value: 'USD' },
    ])
    const [error, setError] = useState('')
    const [quantity, setQuantity] = useState(1)
    const [showPhoneCobru, setShowPhoneCobru] = useState(false)


    //const [amountOption, setAmountOption] = useState('COP')
    //const [isCaptchaValid, setIsCaptchaValid] = useState(false);
    //const [showRecaptcha, setShowRecaptcha] = useState(false)

    /*  const [documentItem, setDocumentItem] = useState(
         { id: 0, label: 'Seleccionar', value: '' },
 
     ) */

    const [loadingIpInfo, setLoadingIpInfo] = useState(false)
    const { recaptchaValue } = useRecaptcha();
    const { isCaptchaValid, setIsCaptchaValid, showRecaptcha, setShowRecaptcha, globalCurrency, setGlobalCurrency,
        isEzy, isPagaHoy, isTucanPay } = useContext(MainContext);
    const documentTypesArray = [
        { id: 0, label: translate('select_label'), value: '' },
        {
            label: `CC - Cédula de ciudadanía`,
            label2: "CC",
            value: 0,
        },
        {
            label: `CE - Cédula de extranjería`,
            label2: "CE",
            value: 1,
        },
        {
            label: `TI - Tarjeta de identidad`,
            label2: "TI",
            value: 2,
        },
        {
            label: `NIT`,
            label2: "NIT",
            value: 3,
        }
    ]
    const [documentTypes, setDocumentTypes] = useState(documentTypesArray)
    const [documentItem, setDocumentItem] = useState(documentTypesArray[1])
    const getCuotasArray = () => {
        let cuotas = [{ id: 1, label: `1 ${translate('dues_label')}`, value: '1' }]
        for (let index = 1; index < 36; index++) {
            cuotas[index] = { id: index + 1, label: `${index + 1} ${translate('dues_label')}`, value: index + 1 };
        }
        return cuotas
    }
    let cuotas = getCuotasArray()
    //let cuotasEnglish = getCuotasArrayEnglish()
    /* const getCuotasArrayEnglish = () => {
        let cuotas = [{ id: 1, label: `1 ${translate('dues_label')}`, value: '1' }]
        for (let index = 1; index < 36; index++) {
            cuotas[index] = { id: index + 1, label: `${index + 1} Dues`, value: index + 1 };
        }
        return cuotas
    } */
    const handleRecaptchaChange = (response) => {
        // Esta función se llama cuando el reCAPTCHA se completa
        // Puedes validar el reCAPTCHA aquí y actualizar el estado
        // para indicar si es válido o no
        //console.log(response);
        setIsCaptchaValid(!!response);
    }
    const onSubmitRecaptchaValue = async () => {
        if (isCaptchaValid) {
            return true
        }
        //setShowRecaptcha(false)
        if (recaptchaValue) {
            const res = await axios.post('/verificar-recaptcha', { recaptchaToken: recaptchaValue });
            if (res?.status == 200 && res?.data) {
                if (res?.data?.success === true) {
                    setIsCaptchaValid(true)
                }
                return res?.data?.success
            } else {
                setIsCaptchaValid(false)
                return false
            }
            //console.log('recaptchaValue', res);
            // Realiza la acción que necesites aquí, como enviar la solicitud al servidor.
            console.log("Puntuación de reCAPTCHA:", recaptchaValue);
        } else {
            setIsCaptchaValid(false)
            return false
            setShowRecaptcha(true)
            console.error("Por favor, completa la verificación reCAPTCHA.");
        }
    }

    //ldqdt7ye
    useEffect(() => {
        if (!!cobruURL) {
            setCobruURL_(cobruURL)

        }
    }, [cobruURL])
    useEffect(() => {
        if (cobru) {
            setCobruItem(cobru)

        }
    }, [cobru])
    useEffect(() => {
        if (isAPhoneNumber) {
            setIsPhoneNumber(isAPhoneNumber)

        }
    }, [isAPhoneNumber])
    useEffect(() => {
        // Buscar Colombia como fallback
        const defaultCountry = countries_new.find(e => e.code === "CO");

        if (!countryFull || !countryFull.country_calling_code || !countryFull.country_code) {
            setCountry(defaultCountry); // Si falta info, usar Colombia
            return;
        }

        try {
            const indicative = parseInt(countryFull.country_calling_code.replace('+', ''), 10);

            // Filtrar todos los países con el mismo código de llamada
            const matches = countries_new.filter(e => e.indicative === indicative);

            let selectedCountry = matches.find(e => e.code === countryFull.country_code) || matches[0];

            // Si no encuentra coincidencias, usar Colombia por defecto
            setCountry(selectedCountry || defaultCountry);
        } catch (error) {
            console.error("Error al procesar el país:", error);
            setCountry(defaultCountry);
        }
    }, [countryFull]);


    useEffect(() => {
        if (!!paymentsMethod) {
            formatPayment()

        }
    }, [paymentsMethod])

    useEffect(() => {
        if (!!params) {
            setLanguage(params?.language === 'en' ? 'en' : 'es');
            fillFields()
            fillUrl()
            if (params?.int === 'true') {

                setCreditCardCuotas({ id: 1, label: '1 Due', value: '1' })
            }

        }
    }, [params])

    useEffect(() => {
        if (paymentsMethodArray && currentPaymentMethod) {
            swapArrayElements_()
        }
    }, [paymentsMethodArray])

    useEffect(() => {
        try {
            if (!!currentPaymentMethod) {
                let name = Object.keys(currentPaymentMethod?.name)[0]
                setPayMethodName(name)
            }

        } catch (error) {
            console.log(error);
        }
    }, [currentPaymentMethod])

    useEffect(() => {
        getBankList()
    }, [])

    useEffect(() => {
        getTRM()
    }, [])

    useEffect(() => {
        if ((!!params && params?.int === 'true') && (payMethodName === 'credit_card' || payMethodName === 'USDT') && !!cobru) {
            setFormValues({
                name: '',
                docType: '',
                doc: '',
                email: '',
                phone: '',
                //amount: ''
            })
            console.log('acaxddddd');
            let arrayNew = [...documentTypesArray, {
                label: `PA - Pasaporte`,
                label2: "PA",
                value: 4,
            },
            {
                label: `PPT - Permiso de Protección Temporal`,
                label2: "PPT",
                value: 5,
            }]
            console.log('cobru', cobru?.owner_document_type);
            let item_ = null
            if (cobru) {
                //alert('OK')
                item_ = arrayNew.find(e => e.value.toString() === cobru?.owner_document_type.toString())
                console.log('item_: ', item_);
                setDocumentItem(item_)
            }

            let name = cobru?.alias
            let palabras = cobru?.alias?.split(' ');
            if (!!params && params?.int === 'true') {
                if (palabras.length < 2) {
                    name = `${cobru?.alias} ${cobru?.alias}`
                }
            }
            setFormValues({
                name: name || '',
                docType: item_?.value.toString() || '',
                //docType:item_?.id || '',
                doc: cobru?.owner_document || '',
                email: cobru?.owner_email || '',
                phone: cobru?.phone ? formatPhoneNumber(cobru?.phone.toString()) : '',
                
            })
            console.log(cobru);
            
            console.log('usee', {
                name: name || '',
                docType: item_?.value.toString() || '',
                doc: cobru?.owner_document || '',
                email: cobru?.owner_email || '',
                phone: cobru?.phone ? formatPhoneNumber(cobru?.phone.toString()) : '',

            });
        }
    }, [params, payMethodName])

    useEffect(() => {
        if (cryptoInterval) {
            clearInterval(cryptoInterval);
        }

        return () => {
            if (cryptoInterval) {
                clearInterval(cryptoInterval);
            }
        }
    }, [])
    useEffect(() => {
        if (daviplataInterval) {
            clearInterval(daviplataInterval);
        }
        //clear interval ()
        /* return () => {
            if (daviplataInterval) {
                clearInterval(daviplataInterval);
            }
        } */
    }, [])

    //console.log('REACT_APP_RECAPTCHA_KEY: ',process.env.REACT_APP_RECAPTCHA_KEY);

    /* useEffect(() => {
        const script = document.createElement('script');
        script.src = `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_RECAPTCHA_KEY}`;
        script.async = true;
        document.body.appendChild(script);
    }, []); */

    const USDPayments = () =>{
        if (payMethodName === 'USDT' ||  payMethodName === 'safetypay' ||  payMethodName === 'credit_card') {
            return true
        }
    }
    const formatPayment = () => {
        let array = []
        let paymentsMethod_ = []
        for (var key in paymentsMethod) {
            if (paymentsMethod.hasOwnProperty(key)) {
                if (paymentsMethod[key] === true) {
                    paymentsMethod_.push(key)
                }
            }
        }
        console.log(paymentsMethod_);
        /* Object.keys(paymentsMethod).map((item, i) => {
            array.push({ [item]: item === item || false, index: i, name: { [item]: item === item }, available:(item === item || false ) })
        }) */
        paymentsMethod_.map((item, i) => {
            array.push({ [item]: item === item || false, index: i, name: { [item]: item === item }, available: (item === item || false) })
        })
        //
        let item_ = {}

        array.map((item, i) => {
            if (item.name[Object.keys(selectedMethod)[0]]) {
                return item_ = item
            }

        })
        console.log('array: ', array, array[0]?.index, item_?.index);
        if (!!params && params?.int === 'true') {

            /* if (array.length > 0 && item_) {
                array = array.filter(item => {
                    const paymentMethods = ['credit_card', 'USDT'];
                    return paymentMethods.includes(Object.keys(item.name)[0]);
                });
            } */
            if (array.length > 0 && item_) {
                const paymentMethods = ['credit_card', 'USDT'];

                array = array
                    .filter(item => paymentMethods.includes(Object.keys(item.name)[0]))
                    .sort((a, b) => {
                        return Object.keys(a.name)[0] === 'credit_card' ? -1 : 1;
                    });
            }
            setPaymentsMethodArray(array)
            setCurrentPaymentMethod(array[0] || null)
            onChangeBTNName(array[0] || null)
            return
        }
        if (array.length > 0 && item_ && array[0]?.index && item_?.index) {

            swapArrayElements(array, array[0]?.index, item_?.index)
            console.log('aca', array, array[0]?.index, item_?.index);
        }
        setPaymentsMethodArray(array)
        setCurrentPaymentMethod(item_ ? item_ : array[0] || null)
        console.log('setPaymentsMethodArray', array);
        onChangeBTNName(item_ ? item_ : array[0] || null)

    }

    const swapArrayElements_ = async () => {

    }

    const validateMaxMinUSD = async (amount_ = '') => {
        if (!!params && params?.language) {
            setLanguage(params?.language === 'en' ? 'en' : 'es');
        }

        /* if (!!params && params?.isInt === 'true') {
            setLanguage('en')

        } */
        let amount_new = amount_ ? amount_ : amount ? amount : ''
        console.log(amount_new);
        if (globalCurrency === 'USD' || (!!params && params?.currency?.toString()?.toLowerCase() === 'usd')) {
            //alert('ok')
            /*  console.log(getDigitsRegister(amount_new));
             console.log( (parseFloat(getDigitsRegister(amount_new)) * parseFloat(trm))/parseFloat(trm));
             console.log( cobru?.max /trm);
             console.log(parseFloat(getDigitsRegister(amount_new)) / parseFloat(trm)); */
            let trmNew = null
            if (!trm) {
                const res = await axios.post('/getSwapMoney', { amount: '10000' });
                if (res?.data?.data && res?.status === 200) {
                    trmNew = res?.data?.data?.price
                }
            }

            const TRM = trmNew ? trmNew : trm ? trm : ''
            const amountTrmUsd = parseFloat(getDigitsRegister(amount_new)) / parseFloat(trm)
            const cobruMaxUSD = parseInt(parseInt(cobru?.max) / parseInt(TRM))
            const cobruMinUSD = parseInt(parseInt(cobru?.min) / parseInt(TRM))
            console.log(cobruMaxUSD, cobruMinUSD);
            console.log(parseFloat(getDigitsRegister(amount_new)) < cobruMinUSD);
            console.log(parseFloat(getDigitsRegister(amount_new)) > cobruMaxUSD);

            if (parseFloat(getDigitsRegister(amount_new)) < cobruMinUSD) {
                if (USDPayments()) {
                    return true
                   } 
                let message = translate('min_amount')

                //let message = !!params && params?.int=== 'true'? 'The minimum amount is ': 'El monto mínimo es '

                setErrorMessages({ ...errorMessages, amount: message + formatUSD(cobruMinUSD.toString(), true) + ' USD' })
                return false
            }
            if (parseFloat(getDigitsRegister(amount_new)) > cobruMaxUSD) {
               if (USDPayments()) {
                return true
               } 

                let message = translate('max_amount')
                //let message = !!params && params?.int=== 'true'? 'The maximum amount is ': 'El monto máximo es '
                setErrorMessages({ ...errorMessages, amount: message + formatUSD(cobruMaxUSD.toString(), true) + ' USD' })
                return false
            }
            if (parseFloat(getDigitsRegister(amount_new)) <= cobruMaxUSD && getDigitsRegister(amount) >= cobruMinUSD) {
                setErrorMessages({ ...errorMessages, amount: '' })
                return true
            }
            return
            /* console.log('OK', (getDigitsRegister(amount) * trm),cobru?.min);
            if ((getDigitsRegister(amount) * trm) < cobru?.min) {
                setErrorMessages({ ...errorMessages, amount: 'El monto minimo es de ' + formatCOPNumber(cobru?.min) })
                return false
            }
            if ((getDigitsRegister(amount) * trm) > cobru?.max) {

                setErrorMessages({ ...errorMessages, amount: 'El monto maximo es de ' + formatCOPNumber(cobru?.max) })
                return false
            }
            if ((getDigitsRegister(amount) * trm) <= cobru?.max && getDigitsRegister(amount) >= cobru?.min) {
                setErrorMessages({ ...errorMessages, amount: '' })
                return true
            }
            return */
        }
    }

    const validateMaxMinCop = (amount_ = '') => {
        let amount_new = amount_ ? amount_ : amount_ ? amount : ''
        console.log('amount_new: ', amount_new);
        if (getDigitsRegister(amount_new) < cobru?.min) {
            if (USDPayments()) {
                return true
               } 
            let message = translate('min_amount')

            setErrorMessages({ ...errorMessages, amount: message + formatCOPNumber(cobru?.min) + ' COP' })
            return false
        }
        if (getDigitsRegister(amount_new) > cobru?.max) {
            if (USDPayments()) {
                return true
            }
            let message = translate('max_amount')

            setErrorMessages({ ...errorMessages, amount: message + formatCOPNumber(cobru?.max) + ' COP' })
            return false
        }
        if (getDigitsRegister(amount_new) <= cobru?.max && getDigitsRegister(amount) >= cobru?.min) {
           
            setErrorMessages({ ...errorMessages, amount: '' })
            return true
        }
    }


    const getTRM = async () => {
        try {
            const res = await axios.post('/getSwapMoney', { amount: '10000' });
            if (res?.data?.data && res?.status === 200) {
                setTrm(res?.data?.data?.price)
            }
            //console.log('getSwapMoney: ',res);
        } catch (error) {
            console.log(error);
        }
    }
    const fillFields = async () => {
        /* if (params?.int === 'true') {
            setLanguage('en')

        } */
        if (!!params && params?.language) {
            setLanguage(params?.language === 'en' ? 'en' : 'es');
        }
        console.log('fillFields: ', params);
        //console.log(params?.currency.toString().toLowerCase() === 'usd');
        let item_ = null
        if (params?.doc_type) {
            item_ = documentTypes.find(e => e.value.toString() === params?.doc_type.toString())
            console.log('item_: ', item_);
            setDocumentItem(item_)
        }

        if (params?.currency) {
            console.log('currency', params?.currency.toString().toLowerCase() === 'usd' ? 'USD' : 'COP');
            setGlobalCurrency(params?.currency.toString().toLowerCase() === 'usd' ? 'USD' : 'COP')
        }
        if (params?.title) {
            setDescription(params?.title)
        }
        if (params?.amount) {


            if ((params?.int && !params?.currency)) {
                console.log('entro aca amount');

                let trmNew = null
                if (!trm) {
                    const res = await axios.post('/getSwapMoney', { amount: '10000' });
                    if (res?.data?.data && res?.status === 200) {
                        trmNew = res?.data?.data?.price
                    }
                }

                const TRM = trmNew ? trmNew : trm ? trm : ''
                setAmount(formatCOPNumberInput(params?.amount * TRM));

            } else if (globalCurrency === 'COP' || (params?.currency.toString().toLowerCase() === 'cop') || !params?.int || !params?.currency) {
                
                setAmount(formatCOPNumberInput(params?.amount));
            } else {
                console.log('entro aca amount', selectedMethod);

                formatUSD(params?.amount)

            }

            validateMaxMinUSD(params?.amount)

        }
        if (params?.int === 'true') {
            console.log('okkkk');
            setIsInt(true)
            //setLanguage('en')
            setGlobalCurrency('USD')
            if (!!params && params?.int === 'true') {

                let amountText = ''
                let amount_ = params?.amount ? params?.amount : amount ? getDigitsRegister(amount) : ''
                if (globalCurrency === 'COP') {
                    amountText = (formatCOPNumberInput(amount_));

                } else {
                    amountText = formatUSD(amount_, true)
                    console.log('amountText: ', amountText);

                }
                setTimeout(() => {
                    console.log({ credit_card: true, index: 7, available: true }, amountText + ' USD');
                    onChangeBTNName({ credit_card: true, index: 7, available: true }, amountText + ' USD')
                }, 500);
            }
            if (params?.amount) {

                formatUSD(params?.amount || '')
            }

        }
        /* 
                Object.keys(params).forEach(element => {
                    if (element === 'int') {
                        setIsInt(true)
                        return
                    }
                }); */
        let name = params?.name
        let palabras = params?.name?.split(' ');
        if (!!params && params?.int === 'true') {
            if (palabras.length < 2) {
                name = `${params?.name} ${params?.name}`
            }
        }
        setFormValues({
            email: params?.email,
            name: name,
            phone: params?.phone ? formatPhoneNumber(params?.phone) : '',
            doc: params?.doc_number ? formatDocument(params?.doc_number) : '',
            docType: item_?.value || ''
        })
        if (item_?.id) {
            let item = documentTypes.find(e => e.value === item_?.id)
            setDocumentItem(item)
        }
    }

    const getBankList = async () => {
        try {
            const res = await axios.post('/api/getBankList')
            if (res.data.status === 200) {
                let banks = res.data.data
                banks = banks.map(bank => {
                    let name = capitalizeSentence(bank.bankName)
                    return ({ label: name, value: bank.bankCode })
                })
                banks = [{ value: '', label: translate('select_bank_label') }, ...banks]
                //console.log('Bancos', banks);
                setBankList(banks)
            }
        } catch (error) {
            setErrorMessage(translate('error_getting_bank_list'))

        }
    }

    const getCobruInfo = async () => {
        try {
            const res = await axios.post('/api/getCobruDetails', { cobruURL: cobruURL })
            console.log('res getCobruInfo', res);

            if (res.data && res?.status === 200) {
                setPaymentsMethod(res.data.data.payment_methods_configuration)
                setShowCobru(true)
            } else if (res?.data === '' && res?.status === 200) {
                setShowCobru(false)
            } else {
                setShowCobru(false)

            }
        } catch (error) {
            console.log('res error: ', error);
            setShowCobru(false)
        }
    }

    const resetData = () => {

        setErrorMessage('')
        setRunTimerCheckDaviplata(false)
        setRunTimerCheck(false)
        setCryptoInterval(null)
        setDaviplataInterval(null)
        setErrorMessages({
            amount: '',
            name: '',
            docType: '',
            doc: '',
            email: '',
            phone: '',
        })
        setLoading(false)
        setOTPNumber('')
        setShowOTP(false)
        setRunTimerCheckDaviplata(false)
        setCountDownDaviplata('')
        clearInterval(daviplataInterval)
        clearInterval(cryptoInterval)
        setDaviplataInfo(null)
        setSuccessCheck(false)
        //setIsInt(false)
    }

    const addParms = async (value = '') => {
        window.history.replaceState(null, null, `?url=${value}`);
    }

    /* const fillUrl = async () => {
        // Obtén la URL actual
        let baseUrl = '';
        let currentUrl = window.location.href;
        let params_ = new URLSearchParams(params);
        console.log('cobruURL', params_.toString());
        let newCobruUrl = cobruURL ? `/${cobruURL}` : ""
        
        let finalUrl = baseUrl +params_.toString() ? newCobruUrl + '?' + params_.toString() : newCobruUrl + '' + params_.toString();
        // Reemplaza "home" con tus nuevos parámetros
        //console.log(finalUrl);
        let newUrl = currentUrl.replace("/home", finalUrl);
        // Reemplaza el estado actual en el historial
        window.history.replaceState(null, null, newUrl);
    } */
    const fillUrl = async () => {
        try {
            let currentUrl = window.location.href;
            let baseUrl = window.location.origin; // Obtener base URL (e.g., https://example.com)
            let params_ = new URLSearchParams(params);

            console.log('cobruURL', params_.toString());

            let newCobruUrl = cobruURL ? `/${cobruURL}` : "";
            let queryString = params_.toString() ? `?${params_.toString()}` : "";

            // Construcción correcta de la URL final
            let finalUrl = `${baseUrl}${newCobruUrl}${queryString}`;

            // Solo actualizar la URL si ha cambiado
            if (currentUrl !== finalUrl) {
                window.history.replaceState(null, null, finalUrl);
            }
        } catch (error) {
            console.error("Error en fillUrl:", error);
        }
    };
    const formatAmount = (value) => {
        if (!value) return 0;
        return parseFloat(parseFloat(value).toFixed(2));
    };
    const pay = async (push = true) => {
        try {

            resetData()
            //Validaciones:
            if (!showPhoneCobru && !validateInput().all()) {
                console.log('ok');

                return
            }
            setLoading(true)
            const res = await onSubmitRecaptchaValue()
            console.log('onSubmitRecaptchaValue: ', res);
            if (res) {
                setShowRecaptcha(false)
            } else {
                setLoading(false)
                setShowRecaptcha(true)
                return
            }

            if (isCaptchaValid || res) {
                console.log('isCaptchaValid');

                let url = null
                let payload_ = {
                    amount: formatAmount(
                        /* payMethodName === 'safeay' ?
                        getDigits(amount) * quantity
                        :
                        cobru?.amount && cobru?.currency_code === 'USD' && !USDPayments()?
                        (((getDigitsRegister(amount) * quantity) * trm)):
                        globalCurrency === 'USD' 
                        ? (((getDigitsRegister(amount) * quantity) * trm)) 
                        : */ getDigits(amount) * quantity),
                    phone: cobruURL,
                    description: description || '',
                    payer_redirect_url: params?.payer_redirect_url || ''
                }
                //console.log('payload_', payload_);
                //return
                if (/* globalCurrency === 'USD' || params?.currency == 'USD' || */ payMethodName === 'safetypay') {
                    payload_.currency_code ='USD'
                }
                //creacion del cobru 
                console.log('payload_', payload_);
                console.log('payMethodName', payMethodName);
                
                if (isPhoneNumber /* && !url */) {
                    const res = await axios.post('/cobru', payload_/* {
                        amount: formatAmount(globalCurrency === 'USD' 
                            ? (((getDigitsRegister(amount) * quantity) * trm)) 
                            : getDigits(amount) * quantity),
                        phone: cobruURL,
                        description: description || '',
                        payer_redirect_url: params?.payer_redirect_url || ''
                    } */)
                    console.log('res cobru: ', res);
                    if (res?.status === 200 && res?.data?.data?.result === 'OK') {
                        setCobruURL_(res?.data?.data?.url)
                        url = res?.data?.data?.url
                        //lksdsd
                        addParms(url)
                    } else {
                        setLoading(false)
                        setErrorMessage(translate('cobru_created_error'))
                        return
                    }

                }


                let payload = {
                    "name": formValues.name,
                    "payment": payMethodName,
                    "cc": formValues.doc ? getDigitsRegister(formValues.doc) : documentItem.value ? getDigitsRegister(documentItem.value) : '',
                    "email": formValues.email,
                    "phone": formValues.phone.replace(/\D/g, ""),
                    "document_type": documentItem.value,
                    payer_country_code: country ? `+${country?.indicative}` : ''
                }


                console.log('payload:', payload);
                console.log('formValues:', formValues);


                /* const payMethodName = Object.keys(currentPaymentMethod.name)[0] */
                if (!!params && params?.callback_url) {
                    payload.callback = params?.callback_url

                }
                if (isEzy) {
                    payload.payer_redirect_url = 'https://www.ezytransfer.co/'

                }
                if (!!params && params?.redirect_url) {

                    payload.payer_redirect_url = params?.redirect_url

                }
                if (payMethodName === 'NEQUI') {
                    /* payload.phone_nequi = formValues.phone.replace(/\D/g, "")
                    payload.push = push */
                    payload.payer_redirect_url = params?.redirect_url

                }
                if (payMethodName === 'pse') {
                    payload.bank = bankPse.value
                    if (!!params && params?.redirect_url) {
                        payload.payer_redirect_url = params?.redirect_url
                    }
                }

                if (payMethodName == 'BTC') {

                }
                /* if (payMethodName === "daviplata") {
                    payload.cc = '996828'
                } */
                if (payMethodName === 'credit_card') {
                    payload.credit_card = creditCardNumber.replace(/\D/g, "")
                    payload.expiration_date = creditCardDate
                    payload.cvv = creditCardCVV
                    payload.dues = parseInt(creditCardCuotas.value)
                }

                if (payMethodName === 'cobru') {
                    payload.payment = 'cobru_phone'
                    let currentUrl = window.location.href;
                    const parsedUrl = new URL(currentUrl);
                    //console.log('parsedUrl: ',parsedUrl);
                    payload.phone = formValues?.phone
                    payload.email = cobru?.owner_email
                    payload.cc = cobru?.owner_document
                    payload.document_type = documentItem?.label2 || 'CC'
                    payload.name = cobru?.name
                }
                console.log('payload: ', payload);
                const resp = await axios.post('/api/getDetailsPay', { cobruURL: isPhoneNumber ? url : cobruURL_, payload })
                console.log('Respuesta directa', resp?.data?.data?.cause);
                if (resp.data.data.error) {
                    //alert('aca')
                    setErrorMessage(resp.data.data.cause || translate('unexpected_error'))
                    setLoading(false)
                    return
                }
                if (resp.data.status === 200) {
                    if (payMethodName === 'cobru') {
                        setLoading(false)
                        setSuccessCheck(true)
                        return

                    }
                    let str = JSON.stringify(resp?.data?.data)
                    console.log(resp.data.data);
                    let dataRes = JSON.parse(resp?.data?.data)
                    const paymentDetails = dataRes ? dataRes[0].fields : false
                    const paymentDetailsCobru = dataRes ? dataRes[1].fields : false
                    JSON.parse(str)

                    console.log('data transformado:', dataRes);
                    console.log('paymentDetails:', paymentDetails);
                    //console.log('str', );
                    if (payMethodName === 'NEQUI') {
                        window.location.href = `${dataRes[0]?.fields?.checkout}`;
                        setLoading(false)

                        /* if (push) {
                            setShowForm(false)
                            setWaitingNequiPay(true)
                            setLoading(false)

                        } else {
                            setWaitingNequiPay(false)

                            console.log(dataRes);
                            setShowForm(false)
                            setQrValue(dataRes[0].fields.ref)
                            setShowQr(true)
                            setLoading(false)

                        } */
                    }

                    if (payMethodName === 'dale') {
                        console.log(dataRes);
                        window.location.href = `${dataRes[0]?.fields?.checkout}`;
                    }
                    if (payMethodName === 'corresponsal_bancolombia') {
                        setPayPin({ code: dataRes[0]?.fields?.project_code, ref: dataRes[0]?.fields?.ref })
                        setShowForm(false)
                        setShowPin(true)
                        setLoading(false)

                        console.log(dataRes);
                    }
                    if (payMethodName === 'efecty') {
                        const date = dataRes[1]?.fields?.expiration_date;

                        const fecha = new Date(date);

                        const newDateFormat = fecha.toISOString().replace(/-/g, "/").slice(0, 10);

                        setPayPin({ code: dataRes[0]?.fields?.project_code, ref: dataRes[0]?.fields?.ref })
                        setShowForm(false)
                        setShowPin(true)
                        setExpDate(newDateFormat)
                        setLoading(false)

                        console.log(dataRes);
                    }
                    if (payMethodName === 'pse') {
                        //console.log('llega aca');
                        console.log(dataRes);
                        window.location.href = `${dataRes[0]?.fields?.checkout}`;
                        setLoading(false)

                    }
                    if (payMethodName === 'safetypay') {
                        //console.log('llega aca');
                        console.log(dataRes);
                        window.location.href = `${dataRes[0]?.fields?.checkout}`;
                        setLoading(false)

                    }
                    if (payMethodName === 'bancolombia_transfer') {
                        //console.log('llega aca');
                        console.log(dataRes);
                        window.location.href = `${dataRes[0]?.fields?.checkout}`;
                        setLoading(false)

                    }
                    if (payMethodName === 'tpaga') {
                        //console.log('llega aca');
                        console.log(dataRes);
                        window.location.href = `${dataRes[0]?.fields?.checkout}`;
                        setLoading(false)

                    }
                    if (payMethodName === 'BTC') {
                        setLoading(false)

                    }
                    if (payMethodName === 'USDT') {
                        console.log("USDT paymentDetails:", paymentDetails);
                        
                        if (!paymentDetails || !paymentDetails.qr_bitcoin) {
                            console.error("No se encontró un QR válido en la respuesta.");
                            setErrorMessage("No se pudo generar el QR de USDT. Inténtalo de nuevo.");
                            setLoading(false);
                            return;
                        }
                    
                        setPaymentDetails(paymentDetails);
                        setRunTimerCheck(true);
                        subtractTimeReload(paymentDetails?.checkout.toString());
                    
                        setTimeout(() => {
                            setShowForm(false);
                            setQrValue(paymentDetails.qr_bitcoin);
                            setCurrencyWallet(paymentDetails.url);
                            setShowQr(true);
                            console.log("QR USDT mostrado correctamente.");
                        }, 100); // Pequeño delay para evitar problemas de renderizado
                    
                        setLoading(false);
                    }
                    
                    /* if (payMethodName === 'USDT') {
                        setPaymentDetails(paymentDetails)
                        setRunTimerCheck(true)
                        subtractTimeReload(paymentDetails?.checkout.toString())
                        setShowForm(false)
                        setQrValue(paymentDetails.qr_bitcoin)
                        setCurrencyWallet(paymentDetails.url)
                        setShowQr(true)
                        setLoading(false)

                    } */
                    if (payMethodName === "credit_card") {
                        setPaymentDetails(paymentDetails)
                        setPaymentDetailsCobru(paymentDetailsCobru)
                        setShowForm(false)
                        setSuccessCheck(true)
                        setLoading(false)

                    }
                    if (payMethodName === "daviplata") {
                        //setShowForm(false)
                        setPaymentDetails(paymentDetails)
                        setPaymentDetailsCobru(paymentDetailsCobru)
                        setRunTimerCheckDaviplata(true)
                        subtractTimeDaviplata(120)
                        setShowOTP(true)
                        setLoading(false)

                    }
                    setLoading(false)

                }

                if (resp.data.data.error) {
                    if (resp.data.data.error === "Payment method is not enabled.") setErrorMessage(translate('payment_method_not_enbled_error'))
                    else setErrorMessage(resp.data.data.error)


                } else if (resp?.data?.status === 400) {


                    setErrorMessage(translate('unexpected_error'))
                    setLoading(false)

                }
                console.log('que devuelve getDetailsPay', resp);
                /*         setQrValue(resp)
                setShowForm(false)
                setShowQr(true) */
                setLoading(false)

            }
        } catch (error) {
            console.log('error pay', error);
            setErrorMessage(translate('unexpected_error'))
            setLoading(false)

        }
    }

    const handleInputChange = (event) => {
        const { value } = event.target;
        const formatValue = formatCOPNumber(value);
        setAmount(formatValue);
    }


    const subtractTimeReload = async (time) => {
        console.log('time: ', time);
        let count = (parseInt(time) * 1000);

        //let count = 1200000;
        const newTimer = setInterval(async () => {
            count -= 1000;
            const newMin = await millisecondsToMinutesAndSeconds(count)
            const { minutes, seconds } = newMin
            /* console.log('seconds', parseInt(seconds));
            console.log('minutes', parseInt(minutes));
            console.log(count); */
            console.log('seconds', parseInt(seconds));
            console.log('minutes', parseInt(minutes))
            setCountDown(minutes + ":" + (seconds < 10 ? '0' : '') + seconds);
            console.log('runTimerCheck', runTimerCheck);
            if (count <= 0 || parseInt(minutes) <= 0 || runTimerCheck === false) {
                console.log('clearInterval:');
                clearInterval(newTimer);
                setPaymentDetails({})
                setCountDown('')
                setRunTimerCheck(false)
                setShowQr(false)

                setShowForm(true)
                //getOpt('SMS');
            } else if ((parseInt(seconds) % 5) === 0) {
                //console.log('5 segussss');
                setPaymentDetails(prevState => {
                    getCryptoStatusReload(prevState)
                    return prevState
                })
            }

        }, 1000);
        setCryptoInterval(newTimer);
    };

    const subtractTimeDaviplata = async (time) => {
        console.log('time: ', time);
        let count = (parseInt(time) * 1000);

        //let count = 1200000;
        const newTimer = setInterval(async () => {
            count -= 1000;
            const newMin = await millisecondsToMinutesAndSeconds(count)
            const { minutes, seconds } = newMin
            /* console.log('seconds', parseInt(seconds));
            console.log('minutes', parseInt(minutes));
            console.log(count); */
            console.log('seconds', parseInt(seconds));
            console.log('minutes', parseInt(minutes))
            setCountDownDaviplata(minutes + ":" + (seconds < 10 ? '0' : '') + seconds);
            console.log('runTimerCheck', runTimerCheckDaviplata);
            if (count <= 0 || parseInt(minutes) <= 0 || runTimerCheckDaviplata === false) {
                console.log('clearInterval:');
                setRunTimerCheckDaviplata(false)
                setCountDownDaviplata('')
                clearInterval(newTimer)
                //getOpt('SMS');
            } /* else if ((parseInt(seconds) % 5) === 0) {
                console.log('5 segundos');
                setRunTimerCheckDaviplata(false)
                setCountDownDaviplata('')


                
            } */

        }, 1000);
        setDaviplataInterval(newTimer);
    };

    /*   useEffect(() => {
          console.log('showQr', showQr);
          console.log('qrValue', qrValue);
      }, [showQr, qrValue]) */

    const getCryptoStatusReload = async (paymentDetails) => {
        /* let status = await API.GET.GetCryptoTransactionStatus(paymentDetails.GOrdenId, paymentDetails.method); */
        try {
            let status = await axios.post('/crypto_status', { transactionID: paymentDetails.GOrdenId, method: paymentDetails.method });
            status = status?.data?.data
            console.log('status: ', status, paymentDetails);
            if (status?.state == "approved") {
                console.log('Pago aprobado, cerrando QR.');
                setRunTimerCheck(false);
                setShowQr(false);
                setPaymentDetails(paymentDetails);
                setSuccessCheck(true);
                clearInterval(cryptoInterval);
                return;
            }
        } catch (error) {
            console.log(error)

        }

    }
    // const GetAvaliableCriptoValues = async () => {
    //     try {
    //         let amount_ = cobru?.amount || getDigits(amount)
    //         if (params?.int === 'true') {
    //             let trmNew = null
    //             if (!trm) {
    //                 const res = await axios.post('/getSwapMoney', { amount: '10000' });
    //                 if (res?.data?.data && res?.status === 200) {
    //                     trmNew = res?.data?.data?.price
    //                 }
    //             }

    //             const TRM = trmNew ? trmNew : trm ? trm : ''
    //             amount_ = globalCurrency === 'USD' ?  (amount_/TRM) : amount_
    //         }
    //         setCurrencyAmount('Calculando...')
    //         console.log('GetAvaliableCriptoValues', AvaliableCriptoMethod());
    //         console.log('GetAvaliableCriptoValues', getDigits(amount_));
    //         if (AvaliableCriptoMethod() && (cobru?.amount || getDigits(amount))) {
    //             let currenciesCripto = await axios.post('/crypto_currencies_available', { amount:amount_ /* cobru?.amount || getDigits(amount) */, currency: payMethodName })
    //             console.log('res de currenciesCripto:', currenciesCripto)
    //             let arrayCryptos = currenciesCripto?.data?.data
    //             if (arrayCryptos) {
    //                 arrayCryptos.forEach(item => {
    //                     if (item.currency == payMethodName) {
    //                         //alert('ok')
    //                         setCurrencyAmount(item.amount)
    //                     }
    //                 });
    //             }
    //             console.log("CURRIENCIES CRIPTO:" + currencyAmount);
    //         }
    //     } catch (error) {
    //         setErrorMessage(translate('error_getting_crypto_value'))
    //         setCurrencyAmount('')

    //         console.log('GetAvaliableCriptoValues', error);
    //     }

    // }
    const GetAvaliableCriptoValues = async () => {
        try {
            setError('')
            console.log("Iniciando GetAvaliableCriptoValues...");
            let amount_ = cobru?.amount || getDigits(amount);
            let trmNew = null;
            console.log('amount_:', amount_);
            
            if (!AvaliableCriptoMethod() || !amount_) {
                return
            }

            console.log("Monto inicial:", amount_);

            if (params?.int === 'true') {
                try {
                    if (!trm) {
                        console.log("Solicitando TRM...");
                        const res = await axios.post('/getSwapMoney', { amount: '10000' });
                        if (res?.data?.data && res?.status === 200) {
                            trmNew = parseFloat(res?.data?.data?.price);
                            console.log("TRM obtenida:", trmNew);
                        } else {
                            console.warn("Respuesta de TRM no válida:", res);
                            throw new Error("No se pudo obtener una TRM válida.");
                        }
                    }
                } catch (trmError) {
                    console.error("Error al obtener TRM:", trmError);
                    throw new Error("No se pudo obtener la TRM.");
                }

                const TRM = trmNew || trm || 1;
                console.log("Usando TRM:", TRM);

                // Validar que TRM sea válida
                if (isNaN(TRM) || TRM <= 0) {
                    throw new Error("La TRM obtenida no es válida: " + TRM);
                }

                if (globalCurrency === 'USD') {
                    try {
                        console.log("Convirtiendo USD a COP...");

                        // Asegurar que amount_ sea válido
                        amount_ = parseFloat(amount_);
                        if (isNaN(amount_) || amount_ <= 0) {
                            throw new Error("El monto ingresado no es válido: " + amount_);
                        }

                        let amountInCOP = Math.round(amount_ * TRM);
                        console.log("Monto convertido a COP (redondeado):", amountInCOP);

                        // Reconvertir a USD y redondear
                        let adjustedAmountUSD = Math.round((amountInCOP / TRM) * 100) / 100;
                        console.log("Monto reconvertido a USD:", adjustedAmountUSD);

                        let difference = amount_ - adjustedAmountUSD;
                        console.log("Diferencia tras reconversión:", difference);

                        if (Math.abs(difference) > 0) {
                            let correctionInCOP = Math.round(difference * TRM);
                            console.log("Ajustando en COP:", correctionInCOP);
                            amountInCOP += correctionInCOP;
                        }

                        amount_ = amountInCOP;
                        console.log("Monto final después de ajuste:", amount_);
                    } catch (conversionError) {
                        console.error("Error en conversión de moneda:", conversionError);
                        throw new Error("Error en la conversión de moneda.");
                    }
                }
            }

            // Asegurar que el monto en COP tenga máximo dos decimales y sea redondeado
            amount_ = Math.round(amount_ * 100) / 100;
            console.log("Monto final con máximo 2 decimales:", amount_);

            setCurrencyAmount('Calculando...');

            if (!AvaliableCriptoMethod()) {
                console.warn("AvaliableCriptoMethod() es inválido.");
                throw new Error("Método de pago no válido.");
            }

            console.log("Consultando criptomonedas disponibles...");
            let currenciesCripto = await axios.post('/crypto_currencies_available', {
                amount: amount_,
                currency: payMethodName
            });

            console.log("Respuesta de currenciesCripto:", currenciesCripto?.data);

            let arrayCryptos = currenciesCripto?.data?.data;
            if (arrayCryptos) {
                let foundCrypto = arrayCryptos.find(item => item.currency === payMethodName);
                if (foundCrypto) {
                    setCurrencyAmount(globalCurrency === 'USD'? Math.round(foundCrypto.amount) : foundCrypto.amount); // Forzar a 1000 exacto
                    console.log("Monto cripto encontrado (redondeado a entero):", foundCrypto.amount);
                }
            }
        } catch (error) {
            console.error("Error general en GetAvaliableCriptoValues:", error);
            setErrorMessage(translate('error_getting_crypto_value'));
            setCurrencyAmount('');
        }
    };



    const getDaviplataStatus = async () => {
        try {
            setLoading(true)
            setErrorMessage('')
            if (!OTPNumber || !cobruURL_ || OTPNumber.length < 6) {
                console.log('invalid');
                setLoading(false)

                return
            }
            console.log({ url: cobruURL_, otp: OTPNumber });
            let status = await axios.post('/daviplata', { url: cobruURL_, otp: OTPNumber });
            if (status?.data?.status === 400) {
                setErrorMessage(status?.data?.data?.message)
                setRunTimerCheckDaviplata(false)
                clearInterval(daviplataInterval)
                setSuccessCheck(false)
                setLoading(false)

            } else if (status?.data?.data?.result == 'OK') {
                setRunTimerCheckDaviplata(false)
                setDaviplataInfo(status?.data?.data?.number_transaction)
                clearInterval(daviplataInterval)
                setShowForm(false)
                setSuccessCheck(true)
                setLoading(false)

            }
            setLoading(false)
            console.log('getDaviplataStatus: ', status);
        } catch (error) {
            console.log(error);
            setErrorMessage(translate('daviplata_status_error'))
            setLoading(false)

        }
    }


    useEffect(() => {
        setErrorMessage('')
    }, [payMethodName, formValues, amount])

    useEffect(() => {
        GetAvaliableCriptoValues()
        if (payMethodName !== 'daviplata') {
            setDocumentTypes([...documentTypesArray, {
                label: `PA - Pasaporte`,
                label2: "PA",
                value: 4,
            },
            {
                label: `PPT - Permiso de Protección Temporal`,
                label2: "PPT",
                value: 5,
            }])
        } else {
            setDocumentItem(documentTypesArray[1])
            setDocumentTypes(documentTypesArray)
        }
    }, [payMethodName])
    useEffect(() => {
        if (!!buttonName && buttonName === 'USDT') {
            //console.log('useEffect');
            GetAvaliableCriptoValues(cobru?.amount || getDigits(amount))
        }
    }, [buttonName])


    const getIndicatiosPin = () => {
        if (payMethodName === 'efecty') {
            return translate('efecty_point_info')
        }
        if (payMethodName === 'corresponsal_bancolombia') {
            return translate('corresponsal_point_info')
        }
    }

    const AvaliableCriptoMethod = () => {
        console.log('payMethodName', payMethodName);
        return (
            payMethodName === 'BTC' ||
            payMethodName === 'BCH' ||
            payMethodName === 'DAI' ||
            payMethodName === 'DASH' ||
            payMethodName === 'USDT'
        );
    }
    const onChangeInput = (e) => {
        setFormValues({ ...formValues, [e.target.name]: e.target.value })
    }
    const onChangeInputFormat = (e, formatPhone = false) => {
        if (formatPhone) {
            setFormValues({ ...formValues, [e.target.name]: formatPhoneNumber(e.target.value) })
            return
        }
        setFormValues({ ...formValues, [e.target.name]: formatDocument(e.target.value) })
    }

    // const QueryIP = async (IP) => {

    //     try {
    //         setLoadingIpInfo(true)
    //         const URL = 'https://panel.validu.co'
    //         const x_api_key = '7oa71voJ.4gFO1Bul2aOS3zfpd9ARvIeeFofPQL0I'
    //         let headers = {
    //             Accept: 'application/json',
    //             'x-api-Key': x_api_key,
    //             "Content-Type": 'application/json',
    //             'Authorization': "Bearer " + await GetAccessTokenValidu()
    //         }
    //         console.log('url QueryIP:', `${URL}/api/ip_validation/?ip=${IP}`);
    //         console.log('headers QueryIP:', headers);
    //         //return
    //         let response = await axios.get(`${URL}/api/ip_validation/?ip=${IP}`, { headers });

    //         console.log('response QueryIP:', response?.data);

    //         if (response.status === 200 && response?.data?.data) {

    //             setIpInfo(response?.data?.data?.data)
    //             console.log('HOLAL ', response?.data?.data?.data);
    //             setCity({
    //                 label: response?.data?.data?.data?.city || ''
    //             })
    //             setState({
    //                 label: response?.data?.data?.data?.regionName || ''
    //             })
    //             //return await response?.data;
    //             setLoadingIpInfo(false)
    //         }

    //     } catch (error) {
    //         console.error('QueryIP error: ', error.response?.data);
    //         if (error.response?.data) {
    //             setLoadingIpInfo(false)
    //             return error.response?.data

    //         }
    //         setLoadingIpInfo(false)
    //         return { "message": error, "error": true }

    //     }


    // }

    const onChangeBTNName = (item, newLabelAmount = '') => {
        console.log('onChangeBTNName:', Object.keys(item)[0] === 'USDT');
        setShowOTP(false)
        setCurrencyAmount('')
        setQrValue(null)
        let name = 'Pagar';
        switch (Object.keys(item)[0]) {
            case 'credit_card':
                let amount_ = ''
                let amountLabel = ''
                /* if (!!newAmount) {
                    amount_=newAmount

                } else {
                    //const amount_ =  (!!params && params?.amount && params?.isInt === 'true' && params?.currency?.toString()?.toLowerCase() === 'usd') ? params?.amount : globalCurrency == 'COP'? amount ? amount : '0' : ''
                     amount_ =  (!!params && params?.amount) ? params?.amount :  amount ? amount : '0'
                     //amountLabel = (!!params && params?.currency?.toString()?.toLowerCase() === 'usd') ? formatUSD(amount_, true)+' USD' :formatCOPNumber(amount_, true)+ ' COP'

                }

                if (!!newAmount) {
                    amountLabel= amount_+ ' COP'
                } else {

                    amountLabel =(globalCurrency === 'USD' || (!!params && params?.currency.toString().toLowerCase() === 'usd')) ? formatUSD(amount_, true)+' USD' :formatCOPNumber(amount_, true)+ ' COP'
                } */
                if (!!newLabelAmount) {
                    amountLabel = newLabelAmount
                } else {

                    amount_ = (!!params && params?.amount) ? params?.amount : amount ? amount : !!cobru?.amount ? cobru?.amount : '0'
                    console.log(amount_);
                    if (cobru?.amount) {
                        amountLabel = ((formatCOPNumber((cobru?.amount)))
                        );

                    } else {


                        amountLabel = (!!params && params?.currency?.toString()?.toLowerCase() === 'usd') ? formatUSD(amount_, true) + ' USD' : formatCOPNumber(amount_, true) + ' COP'
                        console.log('onChangeBTNName globalCurrency: ', amount_);
                    }
                }
                let labelPagar = translate('pay_text')
                //let labelPagar = !!params && params?.int === 'true' ? 'Pay ' : 'Pagar '
                name = labelPagar + amountLabel;
                break;
            case 'NEQUI':
                name = translate('pay_with') + 'Nequi';
                break;
            case 'daviplata':
                name = translate('pay_with') + 'Daviplata';
                break;
            case 'corresponsal_bancolombia':
                name = translate('pay_with') + 'Corresponsal';
                break;
            case 'bancolombia_transfer':
                name = translate('pay_with') + 'Botón Bancolombia';
                break;
            case 'bancolombiapay':
                name = translate('pay_with') + 'Bancolombia pay';
                break;
            case 'pse':
                name = translate('pay_with') + 'PSE';
                break;
            case 'dale':
                name = translate('pay_with') + 'Dale';
                break;
            case 'efecty':
                name = translate('pay_with') + 'Efecty';
                break;
            case 'BTC':
                name = translate('pay_with') + 'BTC';
                break;
            case 'CUSD':
                name = translate('pay_with') + 'CUSD';
                break;
            case 'cobru':
                let currentUrl = window.location.href;
                const parsedUrl = new URL(currentUrl);
                setQrValue(`${parsedUrl?.origin}${parsedUrl?.pathname}`)
                name = `${translate('pay_with')} ${isPagaHoy ? 'Paga Hoy' : 'Cobru'}`;
                break;
            case 'bancolombia_qr':
                name = translate('pay_with') + 'QR Bancolombia';
                break;
            case 'USDT':
                name = translate('pay_with') + 'USDT';
                GetAvaliableCriptoValues()
                break;
            case 'DAI':
                name = translate('pay_with') + 'DAI';
                break;
            case 'baloto':
                name = translate('pay_with') + 'Baloto';
                break;
            case 'DASH':
                name = translate('pay_with') + 'DASH';
                break;
            case 'BCH':
                name = translate('pay_with') + 'BCH';
                break;
            case 'tpaga':
                name = translate('pay_with') + 'TPAGA';
                break;
            case 'credit_card_international':
                name = translate('pay_with') + 'Credit Card International';
                break;
            case 'safetypay':
                name = translate('pay_with') + 'Safetypay';
                setGlobalCurrency('USD')
                break;
            default:
                name = translate('pay_text');
                break;
        }
        setButtonName(name)
        return name;
    }
    const validateInput = () => {
        
        try {

            const amountInput = () => {
                if (globalCurrency === 'USD' || (!!params && params?.currency?.toString().toLowerCase() === 'usd')) {
                    console.log('if uno');

                    //alert('ok')
                    /* console.log('OK', (getDigitsRegister(amount) * trm),cobru?.min);
                    if ((getDigitsRegister(amount) * trm) < cobru?.min) {
                        setErrorMessages({ ...errorMessages, amount: 'El monto minimo es de ' + formatCOPNumber(cobru?.min) })
                        return false
                    }
                    if ((getDigitsRegister(amount) * trm) > cobru?.max) {
    
                        setErrorMessages({ ...errorMessages, amount: 'El monto maximo es de ' + formatCOPNumber(cobru?.max) })
                        return false
                    }
                    if ((getDigitsRegister(amount) * trm) <= cobru?.max && getDigitsRegister(amount) >= cobru?.min) {
                        setErrorMessages({ ...errorMessages, amount: '' })
                        return true
                    }
                    return */
                }
                if (getDigitsRegister(amount) < cobru?.min) {
                    if (USDPayments()) {
                        return true
                       } 
                    console.log('if 2');

                    let message = translate('min_amount')
                    setErrorMessages({ ...errorMessages, amount: message + formatCOPNumber(cobru?.min) })
                    return false
                }
                if (getDigitsRegister(amount) > cobru?.max) {
                    if (USDPayments()) {
                        return true
                    }
                    
                    let message = translate('max_amount')
                    console.log('if 3');

                    setErrorMessages({ ...errorMessages, amount: message + formatCOPNumber(cobru?.max) })
                    return false
                }
                if (getDigitsRegister(amount) <= cobru?.max && getDigitsRegister(amount) >= cobru?.min) {
                    console.log('if 4');

                    setErrorMessages({ ...errorMessages, amount: '' })
                    return true
                }
            }
            const nameInput = () => {
                if (!validateName(formValues?.name, !!params && params?.int === 'true' ? true : false)) {
                    setErrorMessages({ ...errorMessages, name: translate('name_error') })
                    console.log('if 5');

                    return false
                } else {
                    setErrorMessages({ ...errorMessages, name: '' })
                    return true
                }
            }

            const docInput = () => {
                if (getDigitsRegister(formValues?.doc) < 6) {
                    console.log('if 6', formValues);

                    setErrorMessages({ ...errorMessages, doc: translate('document_number_error') })
                    return false
                } else {
                    setErrorMessages({ ...errorMessages, doc: '' })

                    return true
                }
            }
            const emailInput = () => {
                if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(formValues?.email)) {
                    setErrorMessages({ ...errorMessages, email: translate('email_error') })
                    //setErrorMessages({ ...errorMessages, email: '¡Has ingresado una dirección de correo electrónico no válida!' })
                    return false
                } else {
                    setErrorMessages({ ...errorMessages, email: '' })
                    return true
                }
            }
            const phoneInput = () => {
                if (formValues.phone.replace(/\D/g, "").length < 6) {
                    console.log('if 7');

                    setErrorMessages({ ...errorMessages, phone: translate('phone_number_error') })
                    return false
                } else {
                    setErrorMessages({ ...errorMessages, phone: '' })
                    return true
                }
            }
            return {
                amountInput,
                nameInput,
                docInput,
                emailInput,
                phoneInput,
                all: (withPhone = false) => {
                    let validations = nameInput() && docInput() && emailInput() && phoneInput()
                    if (withPhone) {
                        validations = validations && amountInput()
                    }
                    return validations
                }
            }

        } catch (error) {
            console.log('error validate inputs', error);
        }
    }
    const validateDataBasic = () => {
        /*      console.log('que es', formValues?.name);
                console.log('que es', payMethodName);
                console.log('que es', formValues?.doc);
                console.log('que es', formValues?.email);
                console.log('que es', formValues?.phone); */
        //')
        let disabled = false
        if (showPhoneCobru && formValues?.phone && amount) {
            console.log('showPhoneCobru');
            return disabled = false
            
        }
        if (!formValues?.name || !payMethodName || !formValues?.doc || !formValues?.email || !formValues?.phone) {
            /* console.log('primera'); */
            //console.log('segundo if');

            disabled = true
        }
        if (payMethodName === 'pse' && bankPse.value === '') {
            /*  console.log('2'); */
            //console.log('tercer if');

            disabled = true
        }
        if (payMethodName === 'credit_card') {
            if (creditCardNumber.replace(/\D/g, "").length < 13 || creditCardDate.length < 5 || creditCardCVV.length < 3) {
                disabled = true
                /* console.log('3'); */
            }
            //console.log('4 if');

        }
        if (isAPhone) {
            //console.log('errorMessages: ', errorMessages);
            
            if (errorMessages.amount !== '') {
                disabled = true
                //console.log('errorMessages:errorMessages');

            }
            if (params?.amount) {
                disabled =false
            }
            //console.log('5 if');


        }

        if (loading) {
            disabled = true

        }
        //console.log('que es disabled', disabled);
        return disabled
    }
    if (!showCobru) {
        return (
            <div className="flex justify-end items-end">
                <p className="text-center">{translate('cobru_not_exist_error')}</p>
            </div>
        )
    }
    //console.log(cobru);


    const formatUSD = (value, isShow = false) => {
        if (!value || value === '$') {
            setAmount('')
            return ''
        }
        // Elimina todos los caracteres que no sean dígitos o el punto decimal
        const cleanedValue = value.replace(/[^\d.]/g, '');

        // Divide el valor en dólares y centavos
        const [dollars, cents] = cleanedValue.split('.');

        // Formatea los dólares con comas
        const formattedDollars = dollars.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

        // Limita los centavos a dos dígitos
        const formattedCents = cents ? `.${cents.slice(0, 2)}` : '';

        // Combina los dólares y centavos formateados
        const formattedValue = `$${formattedDollars}${formattedCents}`;
        if (isShow) {
            return formattedValue
        }
        console.log('formatUSD:', formattedValue);

        // Actualiza el estado con el valor formateado
        setAmount(formattedValue);
    };

    //console.log('isPhoneNumber: ',isPhoneNumber);


    return (
      <div className="flex items-center justify-start flex-col">
        <NavBarCobru />
        {/*header full*/}
        <CobruNavBar cobru={cobru} loading={loading} error={error} />
        {/*  */}
        <div className="bg-main w-full flex justify-center">
          <div className="flex flex-col justify-center items-center container-data">
            <div className="flex flex-col justify-center items-start w-full">
              <UserNameComponent
                cobruInfo={
                  !!params && params?.title
                    ? params?.title
                    : !!params && params?.name
                    ? params?.name
                    : cobru
                    ? cobru?.alias
                    : ""
                }
              />
              {params?.amount && <SplitAmount cobru={params} />}
              {
                cobru?.amount && !params?.amount && (
                  <SplitAmount cobru={cobru} />
                )

                /*  <p className="price-tag">{`${cobru?.amount ? formatCOPNumber(cobru?.amount, true) : ''}`}</p> */
              }
              {/* {cobru?.amount &&
                cobru?.currency_code === "USD" &&
                !USDPayments() &&
                trm && (
                  <div className="mt-2">
                    <SplitAmount
                      cobru={{
                        amount: parseInt(cobru?.amount) * trm,
                      }}
                    />
                  </div>
                )} */}

              {AvaliableCriptoMethod() &&
                currencyAmount &&
                (!!amount || cobru?.amount) && (
                  <p className="price-tag my-4">
                    {currencyAmount}{" "}
                    {currencyAmount !== translate("calculating_text") + "..."
                      ? payMethodName
                      : ""}
                  </p>
                )}

              {!!params &&
                params?.amount &&
                params?.quantity === 'true'&&
                (params?.int !== "true" || !params?.int) && (
                  <div className="flex items-start flex-row justify-between w-full mt-3">
                    <p className="price-tag">{`${
                      params?.amount
                        ? formatCOPNumberInput(params?.amount * quantity, true)
                        : ""
                    }`}</p>
                    <p className="w-1/2"></p>
                    <div className="flex items-start flex-row h-10">
                      <button
                        onClick={() => {
                          setQuantity((e) => {
                            return e > 1 ? e - 1 : 1;
                          });
                        }}
                        className="quantity_button w-5"
                      >
                        -
                      </button>
                      <p className="mx-6 w-5 ">{quantity}</p>
                      <button
                        onClick={() => {
                          setQuantity((e) => e + 1);
                        }}
                        className="quantity_button w-5"
                      >
                        +
                      </button>
                    </div>
                  </div>
                )}
            </div>
            <div className="flex justify-center flex-col items-center w-full ">
              {showForm ? (
                <div className="w-full">
                  <p className="my-5 text-center">
                    {!!params && params?.int === "true"
                      ? ""
                      : translate("choose_payment_method_label")}
                  </p>
                  <Carousel
                    paymentsMethodArray={paymentsMethodArray}
                    paymentsMethod={paymentsMethod}
                    currentPaymentMethod={currentPaymentMethod}
                    onClickItem={(item) => {
                      setCurrentPaymentMethod(item);
                      onChangeBTNName(item);
                    }}
                  />
                  {!showOTP && (
                    <div className="flex items-center flex-col w-full mt-5">
                      {params?.amount ? (
                        <></>
                      ) : (
                        isPhoneNumber &&
                        (!params?.amount || params?.int || showPhoneCobru) && (
                          //isPhoneNumber && (!params?.amount && (!params?.int || params?.int !== 'true')) &&
                          <div className="w-full">
                            <Input
                              amountSelector={
                                params?.multicurrency === "false"
                                  ? false
                                  : (payMethodName === "credit_card" ||
                                      payMethodName === "USDT") &&
                                    !!params &&
                                    params?.int === "true"
                              }
                              // amountSelector={((payMethodName === 'credit_card' || payMethodName == 'USDT') && (!!params && params?.int === 'true')) ? true : false}
                              options={amountOptions}
                              optionValue={globalCurrency}
                              onChangeValue={async (e) => {
                                setGlobalCurrency(e.target.value);

                                if (params?.amount) {
                                  setAmount("");
                                }
                                if (
                                  !!params &&
                                  params?.int === "true" &&
                                  !params?.amount &&
                                  amount &&
                                  e?.target?.value === "USD"
                                ) {
                                  //console.log('onChangeValue');
                                  let amaount__ = amount;
                                  setAmount("");
                                  let trmNew = null;
                                  if (!trm) {
                                    const res = await axios.post(
                                      "/getSwapMoney",
                                      { amount: "10000" }
                                    );
                                    if (
                                      res?.data?.data &&
                                      res?.status === 200
                                    ) {
                                      trmNew = res?.data?.data?.price;
                                    }
                                  }

                                  const TRM = trmNew ? trmNew : trm ? trm : "";
                                  const amount_new =
                                    parseFloat(getDigitsRegister(amaount__)) /
                                    parseInt(TRM);
                                  console.log(
                                    "amount_new: ",
                                    parseInt(amount_new)
                                  );

                                  setAmount(
                                    formatUSD(
                                      parseFloat(amount_new).toString(),
                                      true
                                    )
                                  );
                                  //alert('USD')
                                  onChangeBTNName(
                                    {
                                      credit_card: true,
                                      index: 7,
                                      available: true,
                                    },
                                    formatUSD(amount_new.toString(), true) +
                                      ` ${e?.target?.value}`
                                  );

                                  setTimeout(() => {
                                    validateMaxMinUSD(amount_new);
                                  }, 500);
                                }
                                if (
                                  !!params &&
                                  params?.amount &&
                                  e?.target?.value === "USD"
                                ) {
                                  console.log("onChangeValue");

                                  setAmount(formatUSD(params?.amount, true));
                                  onChangeBTNName(
                                    {
                                      credit_card: true,
                                      index: 7,
                                      available: true,
                                    },
                                    formatUSD(params?.amount, true) +
                                      ` ${e?.target?.value}`
                                  );

                                  setTimeout(() => {
                                    validateMaxMinUSD(params?.amount);
                                  }, 500);
                                }
                                if (
                                  !!params &&
                                  params?.int === "true" &&
                                  !params?.amount &&
                                  amount &&
                                  e?.target?.value == "COP"
                                ) {
                                  console.log("aca", amount);
                                  let trmNew = null;
                                  if (!trm) {
                                    const res = await axios.post(
                                      "/getSwapMoney",
                                      { amount: "10000" }
                                    );
                                    if (
                                      res?.data?.data &&
                                      res?.status === 200
                                    ) {
                                      trmNew = res?.data?.data?.price;
                                    }
                                  }

                                  const TRM = trmNew ? trmNew : trm ? trm : "";
                                  console.log("TRM", TRM);
                                  console.log(
                                    "GETD",
                                    getDigitsRegister(amount)
                                  );
                                  const amount_new = parseFloat(
                                    getDigitsRegister(amount) * parseInt(TRM)
                                  );
                                  console.log(amount_new);
                                  setAmount(
                                    formatCOPNumberInput(
                                      parseInt(amount_new),
                                      true
                                    )
                                  );
                                  onChangeBTNName(
                                    {
                                      credit_card: true,
                                      index: 7,
                                      available: true,
                                    },
                                    formatCOPNumberInput(
                                      parseInt(amount_new),
                                      true
                                    ) + ` ${e?.target?.value}`
                                  );
                                  setTimeout(() => {
                                    validateMaxMinCop(parseInt(amount_new));
                                  }, 500);
                                  // setAmount(formatCOPNumberInput(getDigitsRegister(params?.amount) * parseFloat(trm), true));
                                }

                                if (
                                  trm &&
                                  !!params &&
                                  params?.amount &&
                                  e?.target?.value == "COP"
                                ) {
                                  const amount_new = parseFloat(
                                    getDigitsRegister(
                                      parseFloat(params?.amount)
                                    ) * parseFloat(trm)
                                  );
                                  console.log(amount_new);
                                  setAmount(
                                    formatCOPNumberInput(
                                      parseInt(amount_new),
                                      true
                                    )
                                  );
                                  onChangeBTNName(
                                    {
                                      credit_card: true,
                                      index: 7,
                                      available: true,
                                    },
                                    formatCOPNumberInput(
                                      parseInt(amount_new),
                                      true
                                    ) + ` ${e?.target?.value}`
                                  );
                                  setTimeout(() => {
                                    validateMaxMinCop(parseInt(amount_new));
                                  }, 500);
                                  // setAmount(formatCOPNumberInput(getDigitsRegister(params?.amount) * parseFloat(trm), true));
                                }
                                //console.log();
                                //setAmount('')
                                setErrorMessages({
                                  amount: "",
                                  name: "",
                                  docType: "",
                                  doc: "",
                                  email: "",
                                  phone: "",
                                });
                                //amount: '')
                                console.log("jeje aca");
                                setGlobalCurrency(e.target.value);
                              }}
                              value={amount}
                              errorMessage={errorMessages.amount}
                              placeholder="$0"
                              onChange={(value) => {
                                console.log("onChange:", value);

                                if (globalCurrency === "COP") {
                                  setAmount(formatCOPNumberInput(value));
                                } else {
                                  formatUSD(value);
                                }
                              }}
                              onBlur={() => {
                                console.log("onBlur");

                                validateInput().amountInput();
                                GetAvaliableCriptoValues();
                                if (
                                  !!params &&
                                  params?.int == "true" &&
                                  payMethodName == "credit_card"
                                ) {
                                  let amountText = "";
                                  let amount_ = params?.amount
                                    ? params?.amount
                                    : amount
                                    ? getDigitsRegister(amount)
                                    : "";
                                  if (globalCurrency === "COP") {
                                    amountText = formatCOPNumberInput(amount_);
                                    console.log("amountText cop:", amountText);
                                  } else {
                                    amountText = formatUSD(amount_, true);
                                    console.log("amountText usd:", amountText);
                                  }

                                  console.log(amount_, globalCurrency);
                                  setTimeout(() => {
                                    onChangeBTNName(
                                      {
                                        credit_card: true,
                                        index: 7,
                                        available: true,
                                      },
                                      amountText + " " + globalCurrency
                                    );
                                  }, 500);
                                } else if (payMethodName == "credit_card") {
                                  console.log("onChangeBTNName cd ");

                                  onChangeBTNName(
                                    {
                                      credit_card: true,
                                      index: 7,
                                      available: true,
                                    },
                                    formatCOPNumberInput(
                                      getDigitsRegister(amount),
                                      true
                                    )
                                  );
                                }
                              }}
                              rate={trm}
                              className={"!text-3xl font-semibold"}
                              //className={(payMethodName !== 'credit_card'/*  && (!!params && params?.int === 'true') */) ? '!text-3xl font-semibold ' : ""}
                              disabled={
                                !!params && params?.amount ? true : false
                              }
                            />
                            {/*  <input value={amount} onChange={(e) => {
                                                    
                                                }} className="payment-input h-14 text-4xl " placeholder="$0" /> */}
                          </div>
                        )
                      )}
                      {payMethodName === "credit_card" ? (
                        <>
                          {!!params && !!params?.int === "true" && (
                            <Input
                              errorMessage={errorMessages.name}
                              name="name"
                              value={formValues.name}
                              placeholder={translate("full_name_label")}
                              onChange={(value) =>
                                setFormValues({ ...formValues, name: value })
                              }
                              onBlur={() => {
                                validateInput().nameInput();
                              }}
                              autocomplete="name"
                            />
                          )}

                          <Input
                            maxlength={"20"}
                            required={true}
                            autocomplete="cc-number"
                            pattern="[0-9]*"
                            type="text"
                            name="creditCardNumber"
                            placeholder={
                              translate(
                                "card_number_label"
                              ) /* !!params && params?.int === 'true'?'Card number' :"Número de la tarjeta" */
                            }
                            value={creditCardNumber}
                            onChange={(value) => {
                              setCreditCardNumber(FormatCreditCard(value));
                            }}
                          />
                          <div className="flex flex-row ">
                            <div className="w-2/3">
                              <Input
                                type="text"
                                className="payment-input"
                                placeholder={
                                  translate(
                                    "month_card_label"
                                  ) /* !!params && params?.int === 'true'? 'MM/YY':"MM/AA" */
                                }
                                name="creditCardDate"
                                value={creditCardDate}
                                onChange={(value) => {
                                  const inputValue = value;
                                  setCreditCardDate(
                                    FormatExpirationDate(inputValue)
                                  );
                                }}
                              />
                            </div>
                            <div className="mr-3" />
                            <div className="w-2/3">
                              <input
                                type="number"
                                className="payment-input"
                                maxLength={"3"}
                                pattern="[0-9]*"
                                placeholder="CVV"
                                value={creditCardCVV}
                                onChange={(e) => {
                                  const inputValue = e.target.value;
                                  const cleanedValue = inputValue.replace(
                                    /\D/g,
                                    ""
                                  );
                                  if (
                                    getFranchise(
                                      creditCardNumber
                                        .toString()
                                        .replace(/\D/g, "")
                                    ) === "American Express"
                                  ) {
                                    if (inputValue.length < 5) {
                                      setCreditCardCVV(inputValue);
                                    }
                                    return;
                                  }
                                  if (inputValue.length < 4) {
                                    setCreditCardCVV(inputValue);
                                  }
                                }}
                              />
                            </div>
                            <div className="mr-3" />

                            <SelectModal
                              items={cuotas}
                              selectedItem={creditCardCuotas}
                              setSelectItem={setCreditCardCuotas}
                            />
                          </div>
                        </>
                      ) : null}
                      {!!params &&
                      params?.int === "true" &&
                      payMethodName === "credit_card" ? (
                        <>
                          <div className="flex flex-row w-full">
                            <div className={"w-4/6 mr-3"}>
                              <SelectModal
                                items={countries_new}
                                selectedItem={country}
                                setSelectItem={setCountry}
                                customLabelKey="label2"
                              />
                            </div>
                            <div className="w-full">
                              <Input
                                type="tel"
                                name="phone"
                                value={formValues.phone}
                                onChange={(e) => {
                                  setFormValues({
                                    ...formValues,
                                    phone: formatPhoneNumber(e),
                                  });
                                }}
                                errorMessage={errorMessages.phone}
                                placeholder={
                                  translate(
                                    "phone_number_label"
                                  ) /* !!params && params?.int === 'true' ? 'Phone number': "Número de celular" */
                                }
                                onBlur={() => validateInput().phoneInput()}
                                autocomplete="tel-national"
                              />
                            </div>
                          </div>
                        </>
                      ) : payMethodName == "cobru" ? (
                        <></>
                      ) : (
                        <>
                          <Input
                            errorMessage={errorMessages.name}
                            name="name"
                            value={formValues.name}
                            placeholder={translate("full_name_label")}
                            onChange={(value) =>
                              setFormValues({ ...formValues, name: value })
                            }
                            onBlur={() => {
                              validateInput().nameInput();
                            }}
                            autocomplete="name"
                          />

                          {params?.int !== "true" && (
                            <div className="flex items-start w-full">
                              <div className="w-2/5 mr-3">
                                <SelectModal
                                  customLabelKey="label2"
                                  items={documentTypes}
                                  selectedItem={documentItem}
                                  setSelectItem={setDocumentItem}
                                />
                              </div>
                              <div className="w-full">
                                <Input
                                  name="doc"
                                  errorMessage={errorMessages.doc}
                                  value={formValues.doc}
                                  onChange={(e) => {
                                    setFormValues({
                                      ...formValues,
                                      doc: formatDocument(e),
                                    });
                                  }}
                                  placeholder={translate("id_number")}
                                  onBlur={() => {
                                    validateInput().docInput();
                                  }}
                                />
                              </div>
                            </div>
                          )}
                          <Input
                            name="email"
                            value={formValues.email}
                            onChange={(value) =>
                              setFormValues({ ...formValues, email: value })
                            }
                            onBlur={() => {
                              validateInput().emailInput();
                            }}
                            errorMessage={errorMessages.email}
                            placeholder={translate("email_label")}
                            autocomplete="email"
                          />
                          <div className="flex flex-row w-full">
                            <div className={"w-4/6 mr-3"}>
                              <SelectModal
                                items={countries_new}
                                selectedItem={country}
                                setSelectItem={setCountry}
                                customLabelKey="label2"
                              />
                            </div>
                            <div className="w-full">
                              <Input
                                autocomplete="tel-national"
                                name="phone"
                                value={formValues.phone}
                                onChange={(e) => {
                                  setFormValues({
                                    ...formValues,
                                    phone: formatPhoneNumber(e),
                                  });
                                }}
                                errorMessage={errorMessages.phone}
                                placeholder={translate("phone_number_label")}
                                onBlur={() => validateInput().phoneInput()}
                              />
                            </div>
                          </div>
                        </>
                      )}
                      {payMethodName === "cobru" && (
                        <div className="my-5 h-full">
                          {showPhoneCobru ? (
                            <div className="w-full">
                              <Input
                                type="text"
                                name="phone"
                                className="w-full"
                                value={formValues.phone}
                                autocomplete="tel-national"
                                onChange={(e) => {
                                  setFormValues({ ...formValues, phone: e });
                                }}
                                placeholder={
                                  translate(
                                    "user_text"
                                  ) /* !!params && params?.int === 'true' ? 'Phone number': "Número de celular" */
                                }
                                onBlur={() => validateInput().phoneInput()}
                              />
                            </div>
                          ) : (
                            <QRCode
                              level="H"
                              size={200}
                              style={{
                                height: "auto",
                                maxWidth: "100%",
                                width: "100%",
                              }}
                              value={qrValue || ""}
                              //value={qrValue || ''}
                              viewBox={`0 0 256 256`}
                            />
                          )}
                          <div className="flex item-center mt-2">
                            <p>Escanea o </p>
                            <p
                              onClick={() => {
                                setShowPhoneCobru(!showPhoneCobru);
                              }}
                              className="ml-1 underline cursor-pointer	"
                            >
                              {!showPhoneCobru
                                ? translate("type_ur_phone")
                                : translate("go_back_qr")}
                            </p>
                          </div>
                        </div>
                      )}
                      {payMethodName === "pse" ? (
                        <>
                          <SelectModal
                            items={bankList}
                            setSelectItem={setBankPse}
                            selectedItem={bankPse}
                          />
                        </>
                      ) : null}
                      {showRecaptcha && (
                        <div>
                          <ReCAPTCHA
                            sitekey={process.env.REACT_APP_RECAPTCHA_KEY_v2}
                            onChange={handleRecaptchaChange}
                          />
                        </div>
                      )}
                      <p className="text-red-600">{errorMessage}</p>
                      <button
                        disabled={validateDataBasic()}
                        onClick={() => pay(true)}
                        className={
                          validateDataBasic()
                            ? "btn-disabled"
                            : isEzy
                            ? "btn-ezy"
                            : isPagaHoy
                            ? "btn-pagahoy"
                            : isTucanPay
                            ? "btn-tucanPay"
                            : "btn"
                        }
                      >
                        {loading ? translate("loading") + "..." : buttonName}
                      </button>

                      {((!!params && params?.redirect_url) ||
                        (!!cobru && cobru?.payer_redirect_url)) && (
                        <button
                          onClick={() => {
                            let url =
                              !!params && params?.redirect_url
                                ? params?.redirect_url
                                : !!cobru && cobru?.payer_redirect_url
                                ? cobru?.payer_redirect_url
                                : "#";
                            if (isEzy) {
                              url = "https://www.ezytransfer.co/";
                            }
                            window.location.href = url.toString();
                            //window.location.href = params?.redirect_url?.toString()
                          }}
                          className={"btn-sec"}
                        >
                          {loading
                            ? translate("loading") + "..."
                            : "Volver al comercio"}
                        </button>
                      )}
                    </div>
                  )}
                  {showOTP && (
                    <div className="grid justify-items-stretch ">
                      {runTimerCheckDaviplata &&
                      payMethodName === "daviplata" /* && !showForm */ ? (
                        <div className="bg-white px-4 py-1 rounded-2xl w-1/5 justify-self-center">
                          <p className="text-center">{countDowDaviplata}</p>
                        </div>
                      ) : null}
                      <Input
                        name="OTP"
                        placeholder="OTP"
                        value={OTPNumber}
                        onChange={(value) => {
                          setOTPNumber(value);
                        }}
                      />
                      <p className="text-red-600">{errorMessage}</p>
                      <button
                        disabled={
                          !countDowDaviplata ||
                          !runTimerCheckDaviplata ||
                          loading ||
                          !OTPNumber ||
                          OTPNumber.length < 6
                        }
                        onClick={() => getDaviplataStatus()}
                        className={
                          !countDowDaviplata ||
                          !runTimerCheckDaviplata ||
                          loading ||
                          !OTPNumber ||
                          OTPNumber.length < 6
                            ? "btn-disabled"
                            : isEzy
                            ? "btn-ezy"
                            : isPagaHoy
                            ? "btn-pagahoy"
                            : isTucanPay
                            ? "btn-tucanPay"
                            : "btn"
                        }
                      >
                        {loading ? translate("loading") + "..." : buttonName}
                      </button>
                    </div>
                  )}
                </div>
              ) : null}
              {/* runTimerCheck && !showForm && payMethodName === 'USDT'
                                ?
                                <p className="price-tag my-4">{currencyAmount} {currencyAmount !== translate('calculating_text') + '...' ? 'USDT' : ''}</p>
                                :
                                null */}
              {runTimerCheck && !showForm ? (
                <div className="bg-white px-4 py-1 rounded-2xl">
                  <p>{countDown}</p>
                </div>
              ) : null}

              {/* 
                            showQr && !showForm && payMethodName === 'NEQUI'
                                ?
                                <div className="flex flex-row items-center justify-center" >
                                    <p className="mr-1">{translate('scan_nequi_text')}</p>

                                </div>
                                :
                                null */}

              {showQr && !showForm ? (
                <div className="my-5 h-full">
                  <QRCode
                    level="H"
                    size={256}
                    style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                    value={qrValue}
                    viewBox={`0 0 256 256`}
                  />
                </div>
              ) : null}
              {showQr && !showForm && payMethodName === "USDT" ? (
                <button
                  className="flex flex-row items-center justify-center"
                  onClick={() => copyClipbord(qrValue)}
                >
                  <p className="mr-1">
                    {qrValue?.substring(0, 14) +
                      "..." +
                      qrValue?.substring(qrValue.length - 3, qrValue?.length)}
                  </p>
                  <div className="bg-white p-2 mx-2 rounded-full">
                    <RiFileCopy2Fill />
                  </div>
                </button>
              ) : null}
              {waitingNequiPay && !showForm ? (
                <div className="w-full h-full flex justify-center items-center flex-col py-5">
                  <Loading />
                  <p className="text-center">
                    {translate("nequi_notification_center_text")}
                    <br /> O
                    <a
                      className="underline cursor-pointer"
                      onClick={() => {
                        pay(false);
                      }}
                    >
                      {translate("pay_with_qr")}
                    </a>
                  </p>
                </div>
              ) : null}
              {showPin && !showForm ? (
                <>
                  <PinPayInfo
                    TitleText1={
                      payMethodName === "efecty"
                        ? translate("project_label") + ":"
                        : translate("agreement_label") + ":"
                    }
                    text1={payPin.code}
                    TitleText2={
                      payMethodName === "efecty"
                        ? "PIN:"
                        : translate("reference_label") + ":"
                    }
                    text2={payPin.ref}
                    exp={expDate}
                    info={getIndicatiosPin()}
                  />
                </>
              ) : null}

              {successCheck && !showForm ? (
                <>
                  <div className="my-2" />

                  <p className="mt-1">
                    REF-{paymentDetails?.pk || paymentDetails?.ordenId}
                  </p>
                  <h2 className="text-3xl font-bold">
                    {translate("successful_payment")}
                  </h2>
                  <img src={checkIcon} className="w-24 my-6" />
                  <div className="w-full border-gray-300 border bg-white p-4">
                    {payMethodName === "daviplata" && daviplataInfo && (
                      <div className="w-full flex justify-between my-1 ">
                        <p>{translate("authorization")}</p>
                        <p>{daviplataInfo}</p>
                      </div>
                    )}
                    <div className="w-full flex justify-between my-1 ">
                      <p>{translate("date_and_time")}</p>
                      <p>{moment().format("MM-DD-YYYY h:mm a")}</p>
                    </div>
                    {/*  <div className="w-full flex justify-between my-1 ">
                                            <p>{translate('fee')}</p>
                                            <p>{formatCOPNumber(parseInt(paymentDetailsCobru.fee_amount))}</p>
                                        </div>
                                        <div className="w-full flex justify-between my-1 ">
                                            <p>{translate('fee_iva')}</p>
                                            <p>{formatCOPNumber(parseInt(paymentDetailsCobru.fee_iva_amount))}</p>
                                        </div> */}
                    <div className="w-full flex justify-between my-1 ">
                      <p>{translate("amount")}</p>
                      <p>
                        {formatCOPNumber(
                          parseInt(
                            /* paymentDetailsCobru.payed_amount ||  */ paymentDetails?.amount
                          )
                        )}
                      </p>
                    </div>
                  </div>
                  <p className="pt-1">
                    {paymentDetails?.ip + " " + getNavInfo().browserName}
                  </p>
                  <div className="my-2" />
                </>
              ) : null}
              <div className="m-3" />
            </div>
            <div className="h-14 w-full">
              <hr />
              <div className="first-child flex flex-row justify-between py-5 ">
                <div className="flex flex-col justify-center">
                  <span className="text-xs text-zinc-400 font-normal">
                    {
                      translate(
                        "more_info"
                      ) /* !!params && params?.int === 'true'? 'More information':'Más información' */
                    }
                  </span>
                  <p className="font-bold text-lg text-neutral-600">
                    {cobru ? cobru?.alias : ""}
                  </p>
                </div>
                <div className="flex flex-row items-center">
                  <div className="bg-white p-2 mx-2 rounded-full">
                    <PhoneContact cobruInfo={cobru} />
                    {/* <a href={cobru?.phone && cobru?.country_code ? `tel:${cobru?.country_code}${cobru?.phone}` : cobru?.phone && cobru?.country_code ? `tel:${cobru?.phone}` : ""}>
                                        <BsTelephoneFill />
                                    </a> */}
                  </div>
                  <div className="bg-white p-2 rounded-full">
                    {/*  <a href={cobru?.owner_email ? `mailto:${cobru?.owner_email}` : ''}>
                                        <MdMail />
                                    </a> */}
                    <EmailContact cobruInfo={cobru} />
                  </div>
                </div>
              </div>
              <hr />
            </div>
          </div>
        </div>
        {/* 
            <p className="text-left">URL</p>
                        {
                cobruURL &&
                <p className="text-left">{cobruURL}</p>
            }

            <p className="text-left">{showCobru.toString()}</p>
 */}
      </div>
    );
}

export default Home; 
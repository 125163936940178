import React, { useEffect} from 'react'

const NotFound = (props) => {
  useEffect(() => {
    if (window.location.hostname && window.location.hostname !== 'localhost') {
      
      window.location= 'https://cobru.co'
    }
  }, [])
  
  return (
    <div>NotFound</div>
  )
}

export default NotFound

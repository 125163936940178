import './App.css';
import Home from './page/Home'
import NotFound from './page/NotFound';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Intro from './page/Intro';
import { MainProvider } from './contexts/MainContext';
import { LanguageProvider } from './contexts/LanguageContext';
import { GeoProvider } from './contexts/GeoContext';


function App() {
  return (
    <div className='h-screen'>
      <MainProvider>
        <LanguageProvider>
          <GeoProvider>
            <BrowserRouter>
              <Routes>
                <Route index path=':cobruURL' element={<Intro />} />
                <Route path='/home' element={<Home />} />
                <Route path='*' element={<NotFound />} />
              </Routes>
            </BrowserRouter>
          </GeoProvider>
        </LanguageProvider>
      </MainProvider>
    </div>


  );
}

export default App;

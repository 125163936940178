import React, { createContext, useContext, useEffect, useState } from 'react';

// Crear el contexto
const GeoContext = createContext();

// Proveedor del contexto
export const GeoProvider = ({ children }) => {
  const [countryFull, setCountryFull] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchCountry = async () => {
      try {
        setLoading(true);
        // Cambia esta URL por la API que prefieras
        const response = await fetch('https://ipapi.co/json/');
        const data = await response.json();
        console.log(data);
        
        setCountryFull(data); // Guarda país
      } catch (error) {
        setCountryFull(null)
        console.log('Error fetching country:', error);
      } finally {
        setLoading(false);
      }
    };

    if (!countryFull) {
        console.log('fetchCountry');
        
      fetchCountry(); // Solo hace el request si `country` es null
    }
  }, [countryFull]);

  return (
    <GeoContext.Provider value={{ countryFull, loading }}>
      {children}
    </GeoContext.Provider>
  );
};

// Hook para usar el contexto
export const useGeo = () => useContext(GeoContext);
import axios from 'axios';
import React, { useState, useEffect, Fragment, useRef, useContext } from 'react'
import { useParams, useNavigate, Link } from "react-router-dom";
import Loading from '../components/Loading';
import PaymentTouchable from '../components/PaymentTouchable';
import { encodeEmail, formatCOPNumber, formatCOPNumberInput, formatMoneySplit, getParams, hasUnderscore, isAPhone, regexBrand } from '../functions';
import { BsTelephoneFill } from "react-icons/bs";
import { FaUserCircle } from "react-icons/fa";
import { MdContactMail, MdMail } from "react-icons/md"
import Modal from '../components/Modal';
import ListItem from '../components/ListItem';
import checkIcon from '../assets/icons/check-icon.png'
import cancelIcon from '../assets/icons/cancel.svg'
import notFoundIcon from '../assets/icons/not-found.svg'
import NavBarCobru from '../components/NavBarCobru';
import ReCAPTCHA from "react-google-recaptcha";
//import RecaptchaComponent from '../components/RecaptchaComponent';
import SplitAmount from '../components/SplitAmount';
import useRecaptcha from '../hooks/useRecaptcha';
import { IoPersonCircleOutline } from "react-icons/io5";
import { MainContext, MainProvider } from '../contexts/MainContext';
import CobruNavBar from '../components/CobruNavBar';
import { useLanguage } from '../contexts/LanguageContext';
import EmailContact from '../components/EmailContact';
import PhoneContact from '../components/PhoneContact';
import UserNameComponent from '../components/UserNameComponent';
import { useGeo } from '../contexts/GeoContext';

const Intro = (props) => {
  const { cobruURL } = useParams()
    const { countryFull,  loading: geoLoading } = useGeo()
  const navigate = useNavigate()
  const [showCobru, setShowCobru] = useState(true)
  const [paymentOptions, setPaymentOptions] = useState(null)
  const [item, setItem] = useState(null)
  const [loading, setLoading] = useState(false)
  const [cobru, setCobru] = useState(null)
  const [cobruInfo, setCobruInfo] = useState(null)
  const [email, setEmail] = useState(null)

  const [cobruURL_, setCobruURL_] = useState(null)
  const [params, setParams] = useState({})
  const [isAPhoneNumber, setIsAPhoneNumber] = useState(false)
  const [showModal, setShowModal] = useState(true)
  //const [showRecaptcha, setShowRecaptcha] = useState(false)
  const [error, setError] = useState('')
  const [cobruPayed, setCobruPayed] = useState(false)
  const [cobrDontExist, setCobrDontExist] = useState(false)

  const [reference, setReference] = useState(null)
  //const [isCaptchaValid, setIsCaptchaValid] = useState(false);

  // Crea una referencia para el elemento div del reCAPTCHA

  const recaptchaRef = useRef(null);


  const { recaptchaValue } = useRecaptcha();
  const { isCaptchaValid, setIsCaptchaValid, showRecaptcha, setShowRecaptcha, isEzy, isPagaHoy, getActiveVariableBrand } = useContext(MainContext);
  const { setLanguage, translate } = useLanguage();
  // 🔹 Función para mantener los parámetros de la URL
  const preserveURLParams = () => {
    const currentParams = new URLSearchParams(window.location.search);
    const updatedURL = `${window.location.origin}${window.location.pathname}?${currentParams.toString()}`;
    window.history.replaceState(null, null, updatedURL);
  };

  const onSubmitRecaptchaValue = async () => {
    //setShowRecaptcha(false)
    if (isCaptchaValid) {
      return true
    }
    if (recaptchaValue) {
      const res = await axios.post('/verificar-recaptcha', { recaptchaToken: recaptchaValue });
      if (res?.status == 200 && res?.data) {
        if (res?.data?.success === true) {
          setIsCaptchaValid(true)
        }
        return res?.data?.success
      } else {
        setIsCaptchaValid(false)
        return false
      }
      //console.log('recaptchaValue', res);
      // Realiza la acción que necesites aquí, como enviar la solicitud al servidor.
      console.log("Puntuación de reCAPTCHA:", recaptchaValue);
    } else {
      setIsCaptchaValid(false)
      return false
      setShowRecaptcha(true)
      console.error("Por favor, completa la verificación reCAPTCHA.");
    }
  }


  const getHostName = () => {
    const EZY = 'link.ezytransfer.co'
    //console.log(window.location.hostname);

  }

  useEffect(() => {
    if (params) {

      preserveURLParams()
    }
  }, [params])


  useEffect(() => {
    getHostName()
    let request = false
    if (!!cobruURL) {
      //getCobruInfo()
      const urlSearchParams = new URLSearchParams(window.location.search);
      const params = Object.fromEntries(urlSearchParams.entries());
      const isBrand = regexBrand(cobruURL)
      const email = cobruInfo?.owner_email || cobruInfo?.email_customer_service || null;
      const encodedEmail = email ? encodeEmail(email) : "#";
      setEmail(encodedEmail)
      if (params) {
        if (!!params && params?.language) {
          setLanguage(params?.language === 'en' ? 'en' : 'es');
        }
        if (!!params && params?.int === 'true') {
          //setLanguage('en')
          preserveURLParams();

        }
        setParams(params)

      }
      if (isAPhone(cobruURL) || isBrand) {
        setIsAPhoneNumber(showAmount(params))
        //setIsAPhoneNumber(isAPhone(cobruURL))
        getCobruInfoByPhone()
      } else {
        getCobruInfo()
      }
      request = true
    }
    return () => {
      request = false
    }
  }, [cobruURL])

  const formatPayments = async (payment) => {
    let array = []

    Object.keys(payment).forEach((element, i) => {
      array.push({
        id: i,
        name: element
      })

    });
    setPaymentOptions(array)
  }
  const scrollToEnd = () => {

    const lastChildElement = recaptchaRef.current;
    console.log(lastChildElement);
    lastChildElement?.scrollIntoView({ behavior: 'smooth', block: "end", });
  };

  /*   const getCobruInfo = async () => {
      try {
        setLoading(true)
        const res = await axios.post('/api/getCobruDetails', { cobruURL: cobruURL })
        const cobruInfo = res.data?.data?.cobru ? JSON.parse(res.data?.data?.cobru) : false
        console.log(res.data?.data);
        if (res.data && res?.status === 200) {
          setCobruInfo(res.data?.data || null)
          if (res.data?.data?.payment_method_enabled) {
  
  
            setPaymentOptions(res.data?.data?.payment_method_enabled)
          }
          if (!!cobruInfo && cobruInfo[0]) {
            console.log(cobruInfo);
            setCobru(cobruInfo[0])
          }
          setShowCobru(true)
          setLoading(false)
        } else if (res?.data === '' && res?.status === 200) {
          setShowCobru(false)
          setLoading(false)
        } else {
          setShowCobru(false)
          setLoading(false)
        }
  
      } catch (error) {
        console.log('res error: ', error);
        setShowCobru(false)
        setLoading(false)
      }
  
    } */

  const createCobru = async () => {

    return true
    try {
      const res = await axios.post('/cobru')
      if (res?.data?.data?.url) {
        setCobruURL_(res?.data?.data?.url)
        return true
      } else {
        //error
        //alert('No se pudo crear el cobru')
        return false

      }
    } catch (error) {
      console.log('error:', error);
      return false

    }
  }
  function filtrarMetodosActivos(metodos) {
    if (!metodos || Object.keys(metodos).length === 0) {
      // Retorna vacío o un mensaje si el objeto no existe o está vacío
      return {};
    }

    // Filtrar y retornar solo los métodos que están activos (true)
    return Object.fromEntries(
      Object.entries(metodos).filter(([key, value]) => value === true)
    );
  }


  // const getCobruInfo = async () => {
  //   try {
  //     setLoading(true);
  //     setError('');

  //     const res = await axios.post('/api/getCobruDetails', { cobruURL });

  //     if (res?.data?.status === 400) {
  //       console.log(res?.data?.data?.code_transaction);
  //       if (res?.data?.data?.code_transaction === "COBRU_ALREADY_PAYED") {
  //         setCobruPayed(true);
  //         setError('El Cobru ya ha sido pagado');
  //         setLoading(false);
  //         setReference(res?.data?.data?.reference || '')
  //         return;
  //       } else if (res?.data?.data?.code_transaction === "UNABLE_TO_GET_COBRU") {
  //         setCobrDontExist(true);
  //         setError(translate('not_found_trasaction'));
  //         setLoading(false);
  //         return;
  //       }
  //     }

  //     // if (res.status === 200 && res.data?.data?.data) {
  //     //   const cobruData = res.data.data.data;
  //     //   setCobruInfo(cobruData);
  //     //   setPaymentOptions(cobruData?.payment_method_enabled);

  //     //   // Filtrar métodos habilitados
  //     //   const filteredMethods = Object.entries(cobruData.payment_method_enabled || {})
  //     //     .filter(([_, enabled]) => enabled)
  //     //     .reduce((acc, [method]) => {
  //     //       acc[method] = true;
  //     //       return acc;
  //     //     }, {});

  //     //   let selectedMethod = Object.keys(filteredMethods)[0]; // Primer método disponible

  //     //   // Si `int=true`, priorizar `credit_card` y `USDT`
  //     //   if (params?.int === 'true') {
  //     //     if (filteredMethods.credit_card) {
  //     //       selectedMethod = "credit_card";
  //     //     } else if (filteredMethods.USDT) {
  //     //       selectedMethod = "USDT";
  //     //     }
  //     //   }

  //     //   // 🔹 Navegar siempre a Home
  //     //   navigate('/home', {
  //     //     state: {
  //     //       methods: filteredMethods,
  //     //       cobruURL,
  //     //       selectedMethod: { [selectedMethod]: true },
  //     //       cobru: cobruData,
  //     //       cobruInfo: cobruData,
  //     //       params,
  //     //       isAPhoneNumber,
  //     //     }
  //     //   });
  //     // }
  //     if (res.status === 200 && res.data?.data?.data) {
  //       const cobruData = res.data.data.data;
  //       setCobruInfo(cobruData);
  //       setPaymentOptions(cobruData?.payment_method_enabled);
    
  //       // Filtrar métodos habilitados
  //       const filteredMethods = Object.entries(cobruData.payment_method_enabled || {})
  //           .filter(([_, enabled]) => enabled)
  //           .reduce((acc, [method]) => {
  //               acc[method] = true;
  //               return acc;
  //           }, {});
    
  //       let sortedMethods = {};
    
  //       // Si int=true, priorizar credit_card y USDT
  //       if (params?.int === 'true') {
  //           if (filteredMethods.credit_card) {
  //               sortedMethods.credit_card = true;
  //           } else if (filteredMethods.USDT) {
  //               sortedMethods.USDT = true;
  //           }
  //       } else {
  //           // Si PSE está habilitado, ponerlo primero
  //           if (filteredMethods.pse) {
  //               sortedMethods.pse = true;
  //           }
  //       }
    
  //       // Agregar los demás métodos habilitados (manteniendo el orden original)
  //       Object.keys(filteredMethods).forEach(method => {
  //           if (!sortedMethods[method]) {
  //               sortedMethods[method] = true;
  //           }
  //       });
    
  //       // Seleccionar el primer método en la lista ordenada
  //       let selectedMethod = Object.keys(sortedMethods)[0];
    
  //       // 🔹 Navegar siempre a Home
  //       navigate('/home', {
  //           state: {
  //               methods: sortedMethods,
  //               cobruURL,
  //               selectedMethod: { [selectedMethod]: true },
  //               cobru: cobruData,
  //               cobruInfo: cobruData,
  //               params,
  //               isAPhoneNumber,
  //           }
  //       });
  //   }
    
  //     setLoading(false);
  //   } catch (error) {
  //     console.error('Error en getCobruInfo:', error);
  //     setError(translate('unexpected_error'));
  //     setLoading(false);
  //   }
  // };

  const getCobruInfo = async () => {
    try {
      setLoading(true);
      setError('');
  
      const res = await axios.post('/api/getCobruDetails', { cobruURL });
  
      if (res?.data?.status === 400) {
        if (res?.data?.data?.code_transaction === "COBRU_ALREADY_PAYED") {
          setCobruPayed(true);
          setError('El Cobru ya ha sido pagado');
          setLoading(false);
          setReference(res?.data?.data?.reference || '');
          return;
        } else if (res?.data?.data?.code_transaction === "UNABLE_TO_GET_COBRU") {
          setCobrDontExist(true);
          setError(translate('not_found_trasaction'));
          setLoading(false);
          return;
        }
      }
  
      if (res.status === 200 && res.data?.data?.data) {
        const cobruData = res.data.data.data;
        setCobruInfo(cobruData);
        setPaymentOptions(cobruData?.payment_method_enabled);
  
        // Métodos habilitados por el backend
        const paymentMethods = cobruData.payment_method_enabled || {};
        const originalMethods = Object.entries(paymentMethods)
          .filter(([_, enabled]) => enabled)
          .reduce((acc, [method]) => {
            acc[method] = true;
            return acc;
          }, {});
  
        let sortedMethods = {};
  
        // 🔸 Filtro por país
        if (/* (!!countryFull && countryFull?.country_name !== 'Colombia' )|| */ res.data?.data?.data?.currency_code =='USD' ) {
          //alert('ok')
          // Solo mostrar credit_card, USDT y safetypay si NO es Colombia
          ['credit_card', 'USDT', 'safetypay'].forEach(method => {
            if (originalMethods[method]) {
              sortedMethods[method] = true;
            }
          });
        } else {
          // Si es Colombia: priorizar según lógica previa
          if (params?.int === 'true') {
            if (originalMethods.credit_card) {
              sortedMethods.credit_card = true;
            } else if (originalMethods.USDT) {
              sortedMethods.USDT = true;
            }
          } else {
            if (originalMethods.pse) {
              sortedMethods.pse = true;
            }
          }
  
          // Agregar el resto sin repetir
          Object.keys(originalMethods).forEach(method => {
            if (!sortedMethods[method]) {
              sortedMethods[method] = true;
            }
          });
        }
  
        // Elegir método por defecto
        const selectedMethod = Object.keys(sortedMethods)[0];
        console.log('navega: ', JSON.stringify( {
          methods: sortedMethods,             // filtrados según país
          originalMethods,                   // todos los métodos habilitados
          cobruURL,
          selectedMethod: { [selectedMethod]: true },
          cobru: cobruData,
          cobruInfo: cobruData,
          params,
          isAPhoneNumber,
          countryName: countryFull?.country_name || '', // opcional
        }));
        
        // 🔹 Navegar a Home con TODO lo necesario
        navigate('/home', {
          state: {
            methods: sortedMethods,             // filtrados según país
            originalMethods,                   // todos los métodos habilitados
            cobruURL,
            selectedMethod: { [selectedMethod]: true },
            cobru: cobruData,
            cobruInfo: cobruData,
            params,
            isAPhoneNumber,
            countryName: countryFull?.country_name || '', // opcional
          }
        });
      }
  
      setLoading(false);
    } catch (error) {
      console.error('Error en getCobruInfo:', error);
      setError(translate('unexpected_error'));
      setLoading(false);
    }
  };
  
  /*  const getCobruInfoByPhone = async () => {
     try {
       setLoading(true)
       const res = await axios.post('/api/getCobruMe', { phone: cobruURL })
       const cobruInfo = res.data?.data?.data ? res.data?.data?.data : false
       console.log("data phone:", cobruInfo);
       if (res.data && res?.status === 200) {
         setCobruInfo(res.data?.data || null)
         setPaymentOptions(cobruInfo?.payment_methods_configuration)
         if (!!cobruInfo) {
           //console.log(cobruInfo);
           setCobru(cobruInfo)
         }
         setShowCobru(true)
         setLoading(false)
       } else if (res?.data === '' && res?.status === 200) {
         setShowCobru(false)
         setLoading(false)
       } else {
         setShowCobru(false)
         setLoading(false)
       }
 
     } catch (error) {
       console.log('res error: ', error);
       setShowCobru(false)
       setLoading(false)
     }
 
   } */
  const getCobruInfoByPhone = async () => {
    try {
      setLoading(true)
      const res = await axios.post('/api/getCobruMe', { phone: cobruURL })

      if (res?.data?.data?.code_transaction === "UNABLE_TO_GET_PROFILE") {
        console.log('UNABLE_TO_GET_PROFILE');

        setCobrDontExist(true)
        setError(res?.data?.data?.message)
        setLoading(false)
        return
      }

      if (res?.data?.data?.data?.brand !== getActiveVariableBrand(hasUnderscore(res?.data?.data?.data?.brand ||'')).toString().toUpperCase()) {
        console.log('res?.data?.data?.data?.brand:',res?.data?.data?.data?.brand);
        console.log('getActiveVariableBrand().toString().toUpperCase():',getActiveVariableBrand().toString().toUpperCase());
        
        setCobrDontExist(true)
        setError('Usuario no existe')
        setLoading(false)
        return
      }
      const cobruInfo = res.data?.data?.data ? res.data?.data?.data : false
      console.log("data phone:", cobruInfo);
      const urlSearchParams = new URLSearchParams(window.location.search);
      const params = Object.fromEntries(urlSearchParams.entries());
      console.log("data phone:", params);
      console.log("data phone:", cobruInfo?.payment_methods_configuration);
      // if (res.data && res?.status === 200) {
      //   setCobruInfo(res.data?.data || null)
      //   setPaymentOptions(cobruInfo?.payment_methods_configuration)
      //   if (!!cobruInfo) {
      //     //console.log(cobruInfo);
      //     setCobru(cobruInfo)

      //     let myPaymentOptions = cobruInfo?.payment_methods_configuration
      //     console.log('myPaymentOptions: ', myPaymentOptions);

      //     let keysPO = Object.keys(myPaymentOptions);
      //     let valuesPO = Object.values(myPaymentOptions);

      //     let firstTrueIndex = valuesPO.findIndex(value => value === true);
      //     let firstTrueKey = keysPO[firstTrueIndex];
      //     let firstTrueKey_ = valuesPO[firstTrueIndex];

      //     console.log(firstTrueKey, firstTrueKey_);


      //     let numEnabledMethods = Object.values(myPaymentOptions).filter(data => data).length
      //     let mySelectedMethod = {}
      //     mySelectedMethod[firstTrueKey] = firstTrueKey_
      //     console.log('mySelectedMethod:', mySelectedMethod);

      //     navigate('/home', {
      //       state: {

      //         methods: myPaymentOptions,
      //         cobruURL: cobruURL,
      //         selectedMethod: mySelectedMethod,
      //         cobru: cobruInfo,
      //         cobruInfo: cobruInfo || null,
      //         params,

      //         isAPhoneNumber: showAmount(params)
      //         //isAPhoneNumber
      //       }
      //     })


      //   }
      if (res.data && res?.status === 200) {
        setCobruInfo(res.data?.data || null);
        setPaymentOptions(cobruInfo?.payment_methods_configuration);
    
        if (!!cobruInfo) {
            setCobru(cobruInfo);
    
            let myPaymentOptions = cobruInfo?.payment_methods_configuration;
            console.log('myPaymentOptions: ', myPaymentOptions);
    
            let sortedPaymentOptions = {};
            
            // Si PSE está disponible, lo ponemos de primero
            if (myPaymentOptions["pse"] === true) {
                sortedPaymentOptions["pse"] = true;
            }
    
            // Agregamos los demás métodos que estén habilitados (true), en el orden original
            for (let key in myPaymentOptions) {
                if (myPaymentOptions[key] === true && key !== "pse") {
                    sortedPaymentOptions[key] = true;
                }
            }
    
            console.log('sortedPaymentOptions:', sortedPaymentOptions);
    
            let firstMethodKey = Object.keys(sortedPaymentOptions)[0]; // Tomamos el primero en la lista
            let mySelectedMethod = { [firstMethodKey]: sortedPaymentOptions[firstMethodKey] };
    
            console.log('mySelectedMethod:', mySelectedMethod);
    
            navigate('/home', {
                state: {
                    methods: sortedPaymentOptions, // Métodos ordenados con PSE primero si aplica
                    cobruURL: cobruURL,
                    selectedMethod: mySelectedMethod,
                    cobru: cobruInfo,
                    cobruInfo: cobruInfo || null,
                    params,
                    isAPhoneNumber: showAmount(params)
                }
            });
        }
    
    

        if (!!params && params?.language) {
          setLanguage(params?.language === 'en' ? 'en' : 'es');
        }
        if (!!params && params?.int === 'true') {
          //setLanguage('en')
          let myPaymentOptions = cobruInfo.payment_methods_configuration
          let keysPO = Object.keys(myPaymentOptions)
          let valuesPO = Object.values(myPaymentOptions)

          let numEnabledMethods = Object.values(myPaymentOptions).filter(data => data).length
          let mySelectedMethod = {}
          mySelectedMethod[keysPO[0]] = valuesPO[0]
          if (!myPaymentOptions?.credit_card) {
            setCobrDontExist(true)
            setError('El usuario no tiene el metodo credit_card activo')
            setLoading(false)
            return

          }

          console.log({

            methods: myPaymentOptions,
            cobruURL: cobruURL,
            selectedMethod: "credit_card",
            cobru: cobruInfo,
            cobruInfo: cobruInfo || null,
            params,
            isAPhoneNumber: showAmount(params)
            //isAPhoneNumber: true
          });

          navigate('/home', {
            state: {

              methods: myPaymentOptions,
              cobruURL: cobruURL,
              selectedMethod: "credit_card",
              cobru: cobruInfo,
              cobruInfo: cobruInfo || null,
              params,
              isAPhoneNumber: true
            }
          })
        } else if (res?.data?.data?.code_transaction === "UNABLE_TO_GET_PROFILE") {
          console.log('UNABLE_TO_GET_PROFILE');
          setCobrDontExist(true)
          setError(res?.data?.data?.message)
          setLoading(false)
          return
        }

        setShowCobru(true)
        setLoading(false)

      } else if (res?.data === '' && res?.status === 200) {
        setShowCobru(false)
        setLoading(false)
      } else {
        setShowCobru(false)
        setLoading(false)
      }

    } catch (error) {
      console.log('res error: ', error);
      setShowCobru(false)
      setLoading(false)
    }

  }

  const navigateTo = async (payment) => {
    const res = await onSubmitRecaptchaValue()
    console.log(res);
    if (res) {
      setShowRecaptcha(false)
    } else {
      setShowRecaptcha(true)
      scrollToEnd()
      return
    }
    if (isCaptchaValid || res) {

      navigate('/home', {
        state: {
          methods: paymentOptions,
          selectedMethod: payment,
          cobruURL: /* isAPhoneNumber ? '' : */ cobruURL,
          cobru,
          cobruInfo,
          params,
          isAPhoneNumber
        }
      })
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    if (isCaptchaValid) {
      // Realiza el envío del formulario al servidor aquí
    } else {
      alert('Por favor, completa el reCAPTCHA.');
    }
  };
  const handleRecaptchaChange = (response) => {
    // Esta función se llama cuando el reCAPTCHA se completa
    // Puedes validar el reCAPTCHA aquí y actualizar el estado
    // para indicar si es válido o no
    //console.log(response);
    setIsCaptchaValid(!!response);
  };

  const showAmount = (params) => {
    //alert('show amount');
    const isbrand = regexBrand(cobruURL)
    if (params?.amount && !params?.int) {
      return false
    } else if (params?.int) {
      return true
    } else if (isAPhone(cobruURL)) {
      return true
    } else if (isbrand) {
      return true
      //isAPhone(isbrand?.phone)
    } else {
      return false
    }
  }

  const returnString = () => {
    const urlCompleta = cobruInfo?.data?.profile_picture;
    //console.log(urlCompleta);
    const cadenaAContener = "https://s3.amazonaws.com/cobru-profile-pictures/";

    if (urlCompleta.includes(cadenaAContener)) {
      return cadenaAContener + urlCompleta
    } else {
      return urlCompleta
    }
  }
  if (geoLoading) {
    return null
  }

  if (error !== '') {
    return (
      <div className="flex h-screen items-center justify-start flex-col">
        <div className="bg-header h-24 w-full flex justify-center">

          <div className="bg-transparent absolute flex imagenContainer">
            {/* {loading && !cobruInfo &&
              <Loading />
            } */}

            {
              !!cobruInfo && cobruInfo?.data?.profile_picture && !loading &&
              <img src={returnString()} className="imagen"></img>
            }
            {
              !cobruInfo?.data?.profile_picture && !loading && !error &&
              <FaUserCircle size={80} />
              /* <img src="https://s3.amazonaws.com/cobru-profile-pictures/uploads/901237347-2022-09-22-32656.jpg" className="imagen"></img> */
            }

            <div className="flex justify-end items-end w-max">
              <p className="text-left text-ref">{`${cobru?.page_title || ''}`}</p>
            </div>



          </div>
          {reference &&
            <div className="flex justify-end items-end image-profile-container mt-1 w-full">
              <p className="text-left text-ref text-zinc-400">REF-{reference}</p>
            </div>
          }
        </div>
        <div className="flex flex-col justify-center items-start">

          <p className="bigBoldText">
            {cobruInfo ? cobruInfo?.name : ''}
          </p>
        </div>
        <div className="bg-main h-full w-full flex justify-center">
          <div className="flex flex-col container-data">
            <div className='w-full h-full flex flex-col items-center'>

              <div className='w-full h-full flex justify-center items-center flex-col'>

                <h2 className="text-3xl font-bold">{cobruPayed ? translate('successful_payment') : cobrDontExist ? "" : 'Ups!'}</h2>
                <img src={cobruPayed ? checkIcon : cobrDontExist ? notFoundIcon : cancelIcon} className="w-24 my-8" />
                <div className="w-full border-gray-300 border bg-white p-4">
                  <div className="w-full flex justify-center my-1 flex-col items-center	 ">
                    <p>{error}</p>

                  </div>
                </div>
                <p className="pt-1"></p>
              </div>
              <div className='w-full flex-col items-center justify-center'>

                <hr />

                <div className="first-child flex flex-row justify-between py-3">
                  <div className="flex flex-col justify-center">
                    <span className="text-xs text-zinc-400 font-medium">{translate('more_info')}</span>
                    <p className="font-bold text-lg text-neutral-600">
                      {cobruInfo?.name}
                    </p>
                  </div>
                  <div className="flex flex-row items-center">
                    <div className="bg-white p-2 mx-2 rounded-full">
                      <PhoneContact cobruInfo={cobruInfo} />
                      {/* <a href={(!!cobruInfo && cobruInfo?.phone) ? `tel:${cobruInfo?.phone}` : (!!cobruInfo && cobruInfo?.phone_customer_service) ? `tel:${cobruInfo?.phone_customer_service}` : "#"}>
                        <BsTelephoneFill />
                      </a> */}
                    </div>
                    <div className="bg-white p-2 rounded-full">
                      <EmailContact cobruInfo={cobruInfo} />
                      {/* <a href={(!!cobruInfo && cobruInfo?.owner_email) ? `mailto:${cobruInfo?.owner_email}` : (!!cobruInfo && cobruInfo?.email_customer_service) ? `mailto:${cobruInfo?.email_customer_service}` : '#'}>
                        <MdMail />
                      </a> */}
                    </div>
                  </div>
                </div>
                <hr />
                <p className="text-xs text-zinc-400 font-medium mt-1 text-center">
                  <a target='_blank' href={isEzy ? 'https://www.ezytransfer.co/terminos-y-condiciones' : 'https://cobru.co/terminos/'}>{translate('terms_and_conditions')}</a> • <a target='_blank' href={isEzy ? 'https://www.ezytransfer.co/privacy' : 'https://cobru.co/wp-content/uploads/Politica-Cobru.pdf'}>{translate('privacy_policy')}</a>
                </p>
              </div>
            </div>

          </div>

        </div>
      </div>
    );
  }

  if (!showCobru) {
    return (
      <div className="flex justify-center items-center">
        <p className="text-center">{'El cobru no existe'}</p>
      </div>
    )
  }
  if (cobrDontExist && error) {
    return (
      <div className="flex justify-center items-center">
        <p className="text-center">{error}</p>
      </div>
    )
  }


  return (
    <div className="flex items-center justify-start flex-col">
      <NavBarCobru />
      <CobruNavBar cobru={cobruInfo?.data} error={error} loading={loading} />
      <div className="bg-main h-screen w-full flex justify-center">
        <div className="flex flex-col justify-center items-center container-data">
          <UserNameComponent cobruInfo={(!!params && params?.title) ? params?.title : cobru ? cobru?.alias : ''} />
          {
            cobru?.amount &&
            <SplitAmount cobru={cobru} />
            /* <div className='flex flex-row'>
              <p className="price-tag p-0" style={{ paddingRight: 0}} >{`${cobru?.amount ?formatMoneySplit(formatCOPNumber(cobru?.amount, true), '.')?.part1 : ''}`}</p>
              {
                formatMoneySplit(formatCOPNumber(cobru?.amount, true), '.')?.part2 &&
                <div className='price-tag flex items-center'style={{ paddingLeft: 0}}>
                  <p className=" text-xs pt-1" >{`${cobru?.amount ?'.'+formatMoneySplit(formatCOPNumber(cobru?.amount, true), '.')?.part2 : ''}`}</p>
                </div>
              }
            </div> */
          }
          {
            (!!params && params?.amount && (params?.int !== 'true' || !params?.int)) &&
            <p className="price-tag">{`${params?.amount ? formatCOPNumberInput(params?.amount, true) : ''}`}</p>
          }
          <div className="flex justify-center flex-col items-center w-full">
            <p className='mt-5'>{translate('how_do_you_want_to_pay')}</p>
            <p className='my-5 text-red-600 '>{error || ''}</p>
            <div className="w-full overscroll-y-auto mb-2">
              {loading &&
                <Loading />
              }
              {
                paymentOptions && !loading &&
                <div >
                  {/* bancolombia y nequi */}
                  <div className='flex flex-row'>
                    {
                      paymentOptions?.bancolombia &&
                      <PaymentTouchable onClick={() => {
                        if (isAPhoneNumber) {
                          const res = createCobru()
                          if (res) {
                            navigateTo({ bancolombia: paymentOptions?.bancolombia })
                          } else {
                            alert(translate('cobru_created_error'))
                          }
                        } else {
                          navigateTo({ bancolombia: paymentOptions?.bancolombia })
                        }
                      }} className={`h-6 w-full`}
                        imgArray={[
                          { id: 'nequi', img: require('../../src/assets/bancolombia.png') }
                        ]}
                        imgClassname={`h-6`}
                      />

                    }
                    {
                      paymentOptions?.NEQUI &&
                      <PaymentTouchable
                        onClick={() => {
                          if (isAPhoneNumber) {
                            const res = createCobru()
                            if (res) {
                              navigateTo({ NEQUI: paymentOptions?.NEQUI })
                            } else {
                              alert(translate('cobru_created_error'))
                            }
                          } else {
                            navigateTo({ NEQUI: paymentOptions?.NEQUI })
                          }
                        }}
                        imgArray={[
                          { id: 'nequi', img: require('../../src/assets/nequi.png') }
                        ]}
                        imgClassname={`h-6`}
                      />

                    }
                  </div>
                  {/* PSE and BTN BANCOLOMBIA */}
                  <div className='flex flex-row mt-4'>
                    {
                      paymentOptions?.pse &&

                      <PaymentTouchable
                        onClick={() => {
                          if (isAPhoneNumber) {
                            const res = createCobru()
                            if (res) {
                              navigateTo({ pse: paymentOptions?.pse })
                            } else {
                              alert(translate('cobru_created_error'))
                            }
                          } else {
                            navigateTo({ pse: paymentOptions?.pse })
                          }
                        }}
                        imgClassname={`h-14`} className='justify-around' imgArray={[
                          { id: 'pse', img: require('../../src/assets/pse.png'), className: 'h-11' },
                          //{ id: 'daviplata', img: require('../../src/assets/daviplata.png'), className: 'h-12' },
                          //{ id: 'rappipay', img: require('../../src/assets/rappi-pay.png'), className: 'h-15' },
                        ]} />

                    }
                    {
                      paymentOptions?.pse && paymentOptions?.bancolombia_transfer &&
                      <div className='w-8' />
                    }
                    {
                      paymentOptions?.bancolombia_transfer &&
                      <PaymentTouchable
                        onClick={() => {
                          if (isAPhoneNumber) {
                            const res = createCobru()
                            if (res) {
                              navigateTo({ bancolombia_transfer: paymentOptions?.bancolombia_transfer })
                            } else {
                              alert(translate('cobru_created_error'))
                            }
                          } else {
                            navigateTo({ bancolombia_transfer: paymentOptions?.bancolombia_transfer })
                          }
                        }}
                        imgClassname={`h-20`}

                        imgArray={[
                          { id: 'corresponsal', img: require('../../src/assets/bancolombia_transfer.png') }
                        ]} />

                    }


                  </div>
                  {/* qrbancolombia and daviplata */}
                  <div className='flex flex-row mt-4'>
                    {
                      paymentOptions?.qr_bancolombia &&

                      <PaymentTouchable
                        onClick={() => {
                          if (isAPhoneNumber) {
                            const res = createCobru()
                            if (res) {
                              navigateTo({ qr_bancolombia: paymentOptions?.qr_bancolombia })
                            } else {
                              alert(translate('cobru_created_error'))
                            }
                          } else {
                            navigateTo({ qr_bancolombia: paymentOptions?.qr_bancolombia })
                          }
                        }}
                        imgClassname={`h-14`} className='justify-around' imgArray={[
                          { id: 'qr_bancolombia', img: require('../../src/assets/qr_bancolombia.png'), className: 'h-11' },
                          //{ id: 'daviplata', img: require('../../src/assets/daviplata.png'), className: 'h-12' },
                          //{ id: 'rappipay', img: require('../../src/assets/rappi-pay.png'), className: 'h-15' },
                        ]} />

                    }
                    {
                      paymentOptions?.qr_bancolombia && paymentOptions?.daviplata &&
                      <div className='w-8' />
                    }
                    {
                      paymentOptions?.daviplata &&
                      <PaymentTouchable
                        onClick={() => {
                          if (isAPhoneNumber) {
                            const res = createCobru()
                            if (res) {
                              navigateTo({ daviplata: paymentOptions?.daviplata })
                            } else {
                              alert(translate('cobru_created_error'))
                            }
                          } else {
                            navigateTo({ daviplata: paymentOptions?.daviplata })
                          }
                        }}
                        imgClassname={`h-20`}

                        imgArray={[
                          { id: 'daviplata', img: require('../../src/assets/daviplata.png') }
                        ]} />

                    }


                  </div>
                  {/* corresponsal and efecty */}
                  <div className='flex flex-row justify-around w-full mt-4'>
                    {
                      paymentOptions?.corresponsal_bancolombia &&
                      <PaymentTouchable
                        onClick={() => {
                          if (isAPhoneNumber) {
                            const res = createCobru()
                            if (res) {
                              navigateTo({ corresponsal_bancolombia: paymentOptions?.corresponsal_bancolombia })
                            } else {
                              alert(translate('cobru_created_error'))
                            }
                          } else {
                            navigateTo({ corresponsal_bancolombia: paymentOptions?.corresponsal_bancolombia })
                          }
                        }}
                        imgClassname={`h-10`}

                        imgArray={[
                          { id: 'corresponsal', img: require('../../src/assets/corresponsal.png') }
                        ]} />

                    }
                    {
                      paymentOptions?.efecty && paymentOptions?.corresponsal_bancolombia &&
                      <div className='w-8' />
                    }
                    {
                      paymentOptions?.efecty &&
                      <PaymentTouchable
                        onClick={() => {
                          if (isAPhoneNumber) {
                            const res = createCobru()
                            if (res) {
                              navigateTo({ efecty: paymentOptions?.efecty })
                            } else {
                              alert(translate('cobru_created_error'))
                            }
                          } else {
                            navigateTo({ efecty: paymentOptions?.efecty })
                          }
                        }}
                        imgArray={[
                          { id: 'efecty', img: require('../../src/assets/efecty.png') }
                        ]}
                        imgClassname={`h-6`}
                      />
                    }
                  </div>
                  {/* credit_card */}
                  <div className='flex w-full mt-4'>
                    {
                      paymentOptions?.credit_card &&

                      <PaymentTouchable
                        onClick={() => {
                          if (isAPhoneNumber) {
                            const res = createCobru()
                            if (res) {
                              navigateTo({ credit_card: paymentOptions?.credit_card })
                            } else {
                              alert(translate('cobru_created_error'))
                            }
                          } else {
                            navigateTo({ credit_card: paymentOptions?.credit_card })
                          }
                        }}
                        imgClassname={`h-6`} className='justify-around' imgArray={[
                          { id: 'visa', img: require('../../src/assets/visa.png') },
                          { id: 'mastercard', img: require('../../src/assets/mastercard.png'), className: 'h-10' },
                          { id: 'amex', img: require('../../src/assets/amex.png'), className: 'h-11' },
                        ]} />

                    }
                  </div>
                  {
                    paymentOptions?.tpaga &&
                    <div className='flex w-full mt-4'>
                      <PaymentTouchable
                        onClick={() => {
                          if (isAPhoneNumber) {
                            const res = createCobru()
                            if (res) {
                              navigateTo({ tpaga: paymentOptions?.tpaga })
                            } else {
                              alert(translate('cobru_created_error'))
                            }
                          } else {
                            navigateTo({ tpaga: paymentOptions?.tpaga })
                          }
                        }}
                        imgArray={[
                          { id: 'tpaga', img: require('../../src/assets/tpaga.png') }
                        ]}
                        imgClassname={`h-24`}
                      />
                    </div>
                  }
                  {/* dale and cobru */}
                  <div className='flex flex-row justify-around w-full mt-4'>
                    {
                      paymentOptions?.dale &&
                      <PaymentTouchable
                        onClick={() => {
                          if (isAPhoneNumber) {
                            const res = createCobru()
                            if (res) {
                              navigateTo({ dale: paymentOptions?.dale })
                            } else {
                              alert(translate('cobru_created_error'))
                            }
                          } else {
                            navigateTo({ dale: paymentOptions?.dale })
                          }
                        }}
                        imgClassname={`h-7`}

                        imgArray={[
                          { id: 'dale', img: require('../../src/assets/dale.png') }
                        ]} />

                    }
                    {
                      paymentOptions?.dale && paymentOptions?.cobru &&
                      <div className='w-8' />
                    }
                    {
                      paymentOptions?.cobru &&

                      <PaymentTouchable
                        onClick={() => {
                          if (isAPhoneNumber) {
                            const res = createCobru()
                            if (res) {
                              navigateTo({ cobru: paymentOptions?.cobru })
                            } else {
                              alert(translate('cobru_created_error'))
                            }
                          } else {
                            navigateTo({ cobru: paymentOptions?.cobru })
                          }
                        }}
                        imgArray={[
                          { id: 'cobru', img: require('../../src/assets/cobru.png') }
                        ]}
                        imgClassname={`h-8`}
                      />
                    }
                  </div>
                  {/* dale and cobru */}
                  <div className='flex flex-row justify-around w-full mt-4'>
                    {
                      paymentOptions?.BTC &&
                      <PaymentTouchable
                        imgClassname={`h-10 `}
                        onClick={() => {
                          if (isAPhoneNumber) {
                            const res = createCobru()
                            if (res) {
                              navigateTo({ BTC: paymentOptions?.BTC })
                            } else {
                              alert(translate('cobru_created_error'))
                            }
                          } else {
                            navigateTo({ BTC: paymentOptions?.BTC })
                          }
                        }}
                        imgArray={[
                          { id: 'BTC', img: require('../../src/assets/bitcoin.png') }
                        ]} />

                    }
                    {
                      paymentOptions?.BTC && paymentOptions?.USDT &&
                      <div className='w-8' />
                    }
                    {
                      paymentOptions?.USDT &&
                      <PaymentTouchable
                        onClick={() => {
                          if (isAPhoneNumber) {
                            const res = createCobru()
                            if (res) {
                              navigateTo({ USDT: paymentOptions?.USDT })
                            } else {
                              alert(translate('cobru_created_error'))
                            }
                          } else {
                            navigateTo({ USDT: paymentOptions?.USDT })
                          }
                        }}
                        imgArray={[
                          { id: 'USDT', img: require('../../src/assets/usdt.png') }
                        ]}
                        imgClassname={`h-10`}
                      />
                    }
                    {
                      paymentOptions?.safetypay &&
                      <PaymentTouchable
                        onClick={() => {
                          if (isAPhoneNumber) {
                            const res = createCobru()
                            if (res) {
                              navigateTo({ safetypay: paymentOptions?.safetypay })
                            } else {
                              alert(translate('cobru_created_error'))
                            }
                          } else {
                            navigateTo({ safetypay: paymentOptions?.safetypay })
                          }
                        }}
                        imgArray={[
                          { id: 'safetypay', img: require('../../src/assets/safetypay.png') }
                        ]}
                        imgClassname={`h-10`}
                      />
                    }
                    {
                      paymentOptions?.celo &&
                      <PaymentTouchable
                        onClick={() => {
                          if (isAPhoneNumber) {
                            const res = createCobru()
                            if (res) {
                              navigateTo({ celo: paymentOptions?.celo })
                            } else {
                              alert(translate('cobru_created_error'))
                            }
                          } else {
                            navigateTo({ celo: paymentOptions?.celo })
                          }
                        }}
                        imgArray={[
                          { id: 'celo', img: require('../../src/assets/celo.png') }
                        ]}
                        imgClassname={`h-6`}
                      />
                    }

                  </div>
                </div>
              }


            </div>
            {
              showRecaptcha &&
              <div className='mb-2'>
                <ReCAPTCHA

                  sitekey={process.env.REACT_APP_RECAPTCHA_KEY_v2}
                  //sitekey="6LfDwMgnAAAAALr-hnU4ZXHdCLmDdBxk-fe9PmvY"
                  onChange={handleRecaptchaChange}
                />
              </div>

            }
            <hr />
            <div className="first-child flex flex-row justify-between py-3">
              <div className="flex flex-col justify-center">
                <span className="text-xs text-zinc-400 font-medium">{translate("more_info")}</span>
                <p className="font-bold text-lg text-neutral-600">
                  {cobruInfo?.name}
                </p>
              </div>
              <div className="flex flex-row items-center">
                <div className="bg-white p-2 mx-2 rounded-full">
                  <PhoneContact cobruInfo={cobruInfo} />
                  {/* <a href={(!!cobruInfo && cobruInfo?.phone) ? `tel:${cobruInfo?.phone}` : (!!cobruInfo && cobruInfo?.phone_customer_service) ? `tel:${cobruInfo?.phone_customer_service}` : "#"}>
                   <BsTelephoneFill />

                  </a> */}
                </div>
                <div className="bg-white p-2 rounded-full">
                  <EmailContact cobruInfo={cobruInfo} />
                  {/* <a href={(!!cobruInfo && cobruInfo?.owner_email) ? `mailto:${cobruInfo?.owner_email}` : (!!cobruInfo && cobruInfo?.email_customer_service) ? `mailto:${cobruInfo?.email_customer_service}` : '#'}>
                    <MdMail />
                  </a> */}
                </div>
              </div>
            </div>
            <hr />
            <div ref={recaptchaRef} className="text-xs text-zinc-400 font-medium mt-1 text-center mb-10">
              <a target='_blank' href={isEzy ? 'https://www.ezytransfer.co/terminos-y-condiciones' : 'https://cobru.co/terminos/'}>{translate('terms_and_conditions')}</a> • <a target='_blank' href={isEzy ? 'https://www.ezytransfer.co/privacy' : 'https://cobru.co/wp-content/uploads/Politica-Cobru.pdf'}>{translate('privacy_policy')}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Intro